import { isBefore, isEqual, addDays, format } from 'date-fns'

export const base64ToFile = (base64, filename) => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

export const extractPostalCode = (address) => {
    const postalCodeMatch = address.match(/\b\d{5}\b/);
    return postalCodeMatch ? postalCodeMatch[0] : '';
};

export const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

export const extractTime = (dateTime) => {
    if (!dateTime) return '-';
    const parts = dateTime.split(' ');
    return parts.length > 1 ? parts[parts.length - 1] : dateTime;
};

export const generateDateRange = (startDate, endDate) => {
    let dates = [];

    let currentDate = startDate;

    while (isBefore(currentDate, endDate) || isEqual(currentDate, endDate)) {
        dates.push(currentDate);
        currentDate = addDays(currentDate, 1);
    }

    return dates;
}

export const formatMonthYear = (currentMonth) => {
    // Create a new Date object from the currentMonth string
    const date = new Date(currentMonth + "-01"); // Add "-01" to ensure it's a valid date
  
    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Extract the month and year
    const month = monthNames[date.getMonth()]; // getMonth() returns 0-based index
    const year = date.getFullYear();
  
    return `${month} ${year}`;
  };

export const getCurrentWeekDates = () => {
    const today = new Date();
    const firstDayOfWeek = today.getDate() - today.getDay() + 1; // Setel hari Senin sebagai hari pertama
    const startOfWeek = new Date(today.setDate(firstDayOfWeek));
    const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 6));

    const dates = [];
    let currentDate = new Date(startOfWeek);

    while (currentDate <= endOfWeek) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}

export const handleChangeApp = async (link) => {
    window.location.assign(`${link}`);
};

export const handleModalDataSubmit = async (data, mutate, handleFormSubmit, onClose) => {
    try {
        await mutate(data); // Call mutate with the data payload
        handleFormSubmit(); // Execute handleFormSubmit after mutation succeeds
        onClose(); // Execute onClose after mutation succeeds
    } catch (error) {
        // Handle any errors here if needed
        console.error('Error sending Data:', error);
    }
};

export const getInitialTimers = () => {
    const savedTimers = JSON.parse(localStorage.getItem('timers'));
    return savedTimers || { punchWidget: 0, breakWidget: 0 };
};

// Utility to save to localStorage
export const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

// Utility to get from localStorage
export const getFromLocalStorage = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
};

// export const getInitialAttendanceDataStates = () => {
//     const storedData = localStorage.getItem('attendanceDataStates');
//     return storedData ? JSON.parse(storedData) : {
//         punchInData: '',
//         breakData: '',
//         punchOutData: '',
//         returnBreakData: ''
//     };
// };

export const getInitialAttendanceDataStates = (shiftOption) => {
    const storedData = localStorage.getItem('attendanceDataStates');
    let defaultAttendanceData = {};

    // Initialize with empty data for each shift option
    shiftOption.forEach(shift => {
        defaultAttendanceData[shift.value] = {
            name: shift.label,
            punchInData: '',
            punchOutData: '',
            breakData: '',
            returnBreakData: ''
        };
    });

    console.log('default attendance data:', defaultAttendanceData);

    if (storedData) {
        const parsedStoredData = JSON.parse(storedData);
        
        // Check if parsedStoredData is an empty object
        if (Object.keys(parsedStoredData).length === 0) {
            return defaultAttendanceData;
        }

        return parsedStoredData;
    }

    return defaultAttendanceData;
};

export const getInitialIsTimerRunning = () => {
    const savedIsTimerRunning = JSON.parse(localStorage.getItem('isTimerRunning'));
    return savedIsTimerRunning || { punchWidget: false, breakWidget: false };
};

export const getCurrentDate = () => new Date().toISOString().split('T')[0];

// set current date on local storage for performing checking if there is any date change
export const initializeCurrentDate = () => {
    const currentDate = getCurrentDate();
    if (!localStorage.getItem('currentDate')) {
        localStorage.setItem('currentDate', currentDate);
    }
};

export const isValidTime = (time) => {
    // Here, you can check the format of the time or if it's empty
    // Example: return true if time is not empty and matches a certain format
    return time && time.length > 0; // Simple check, adjust as necessary
};

// process time data formatting for widget showing
export const processTimeData = (time) => {
    const dateTime = new Date(time);

    const formattedDate = dateTime.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    });

    const formattedHour = dateTime.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });

    const formattedTime = `${formattedDate} ${formattedHour}`;
    return formattedTime;
}



// // function for handling different case of which widget states to show based on attendance data
// export const handleSettingWidgetState = (todayData, latestData, dispatch, setWidgetState, setIsTimerRunning, setAttendanceDataStates) => {
//     if (latestData && !latestData.punchOut) {
//         // Case 1: No punchOut record for last punchIn
//         dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showDisableBreakWidget'], value: true }));
//     } else {
//         if (!todayData || !todayData.punchIn) {
//             // Case 2: today's punchIn is not filled
//             dispatch(setWidgetState({ widgetKeys: ['showPunchInWidget', 'showDisableBreakWidget'], value: true }));
//             setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
//         } else if (todayData.punchIn && (!todayData.breaks || todayData.breaks.length === 0)) {
//             // Case 3: punchIn is filled but no breaks
//             dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showBreakWidget'], value: true }));
//             setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
//             setAttendanceDataStates(prev => ({ ...prev, punchInData: processTimeData(todayData.punchIn) }));
//         } else if (todayData.punchIn && !todayData.punchOut && todayData.breaks.length > 0) {
//             const lastBreak = todayData.breaks[todayData.breaks.length - 1];
//             if (!lastBreak.returnFromBreak) {
//                 // Case 4: punchIn, breaks exist, but no returnFromBreak
//                 dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showReturnBreakWidget'], value: true }));
//                 setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: true }));
//                 setAttendanceDataStates(prev => ({ ...prev, breakData: processTimeData(lastBreak.breakTime) }));
//             } else {
//                 // Case 5: punchIn, breaks exist, returnFromBreak exist, but no punchOut
//                 dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showBreakWidget'], value: true }));
//                 setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
//                 setAttendanceDataStates(prev => ({ ...prev, returnBreakData: processTimeData(lastBreak.returnFromBreak) }));
//             }

//         } else if (todayData.punchIn && todayData.punchOut && todayData.breaks.length > 0) {
//             // Case 6: punchIn, punchOut, and breaks with returnFromBreak exist
//             dispatch(setWidgetState({ widgetKeys: ['showPunchInWidget', 'showDisableBreakWidget'], value: true }));
//             setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
//             setAttendanceDataStates(prev => ({ ...prev, punchOutData: processTimeData(todayData.punchOut) }));
//         }
//     }
// };

export const handleSettingWidgetState = (todayData, latestData, dispatch, setWidgetState, setIsTimerRunning, setAttendanceDataStates) => {
    // if (latestData && !latestData.punchOut) {
    //     // Case 1: No punchOut record for last punchIn
    //     dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showDisableBreakWidget'], value: true }));
    // } else {
        if (!todayData || !todayData.punchIn) {
            // Case 2: Today's punchIn is not filled
            dispatch(setWidgetState({ widgetKeys: ['showPunchInWidget', 'showDisableBreakWidget'], value: true }));
            setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
        } else if (todayData.punchIn && !todayData.punchOut) {
            if (todayData.breaks && todayData.breaks.length > 0) {
                const lastBreak = todayData.breaks[todayData.breaks.length - 1];
                if (!lastBreak.returnFromBreak) {
                    // Case 4: punchIn, breaks exist, but no returnFromBreak
                    dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showReturnBreakWidget'], value: true }));
                    setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: true }));
                    setAttendanceDataStates(prev => ({ ...prev, breakData: processTimeData(lastBreak.breakTime) }));
                } else {
                    // Case 5: punchIn, breaks exist, returnFromBreak exists, but no punchOut
                    dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showBreakWidget'], value: true }));
                    setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
                    setAttendanceDataStates(prev => ({ ...prev, returnBreakData: processTimeData(lastBreak.returnFromBreak) }));
                }
            } else {
                // Case 6: punchIn exists, punchOut does not exist, but no breaks
                dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showBreakWidget'], value: true }));
                setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
                setAttendanceDataStates(prev => ({ ...prev, punchInData: processTimeData(todayData.punchIn) }));
            }
        } else if (todayData.punchIn && todayData.punchOut) {
            if (todayData.breaks && todayData.breaks.length > 0) {
                const lastBreak = todayData.breaks[todayData.breaks.length - 1];
                if (!lastBreak.returnFromBreak) {
                    // Case 7: punchIn, punchOut, breaks exist, but no returnFromBreak
                    dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showReturnBreakWidget'], value: true }));
                    setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: true }));
                    setAttendanceDataStates(prev => ({ ...prev, breakData: processTimeData(lastBreak.breakTime) }));
                } else {
                    // Case 8: punchIn, punchOut, breaks exist, returnFromBreak exists
                    dispatch(setWidgetState({ widgetKeys: ['showPunchInWidget', 'showDisableBreakWidget'], value: true }));
                    setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
                    setAttendanceDataStates(prev => ({ ...prev, returnBreakData: processTimeData(lastBreak.returnFromBreak), punchOutData: processTimeData(todayData.punchOut) }));
                }
            } else {
                // Case 9: punchIn and punchOut exist but no breaks data
                dispatch(setWidgetState({ widgetKeys: ['showPunchInWidget', 'showDisableBreakWidget'], value: true }));
                setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
                setAttendanceDataStates(prev => ({ ...prev, punchOutData: processTimeData(todayData.punchOut) }));
            }
        }
    // }
};


export const getAndSetAllowedTime = (matchedShift, handleDispatchAllowedTime, attendanceSetting) => {
    if (!matchedShift || !attendanceSetting) return;
  
    // Extract times from the shift data
    // console.log('get and set allowed time run');
    const { startTime, endTime, startBreakTime, endBreakTime } = matchedShift;
  
    // Helper function to format time into HH:MM
    const formatTime = (date) => date.toTimeString().slice(0, 5);
  
    // Helper function to parse time in HH:MM format
    const parseTime = (timeStr) => {
      const [hours, minutes] = timeStr.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    };
  
    // Parse shift times into Date objects
    const startShiftTime = parseTime(startTime);
    const endShiftTime = parseTime(endTime);
    const startBreakShiftTime = parseTime(startBreakTime);
    const endBreakShiftTime = parseTime(endBreakTime);
  
    // Calculate allowed start and end times using attendanceSetting
    const offsetBeforeMilliseconds = (attendanceSetting.earlyPunchIn || 1) * 60 * 1000;
    const offsetAfterMilliseconds = (attendanceSetting.punchInDispen || 1) * 60 * 1000;
  
    const allowedStartTime = new Date(startShiftTime.getTime() - offsetBeforeMilliseconds);
    const allowedEndTime = new Date(endShiftTime.getTime() + offsetAfterMilliseconds);
  
    // Directly format the start and end break times without applying offsets
    const allowedStartBreakTime = formatTime(startBreakShiftTime);
    const allowedEndBreakTime = formatTime(endBreakShiftTime);
  
    // Format the calculated times into HH:MM strings
    const formattedAllowedTimes = {
      allowedStartTime: formatTime(allowedStartTime),
      allowedEndTime: formatTime(allowedEndTime),
      allowedStartBreakTime,  // Already formatted
      allowedEndBreakTime,     // Already formatted
    };

  
    // Set the allowed times using the setter
    handleDispatchAllowedTime(formattedAllowedTimes);
  };

