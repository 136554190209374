import BreakIcon from "assets/icon/BreakSVG";
import { formatTime } from "utils/utils";

const BreakDisabledWidget = ({ breakTime, timer }) => {
    const formattedBreakTime = breakTime ? breakTime : "No Break Time";
    const formattedTimer = timer ? formatTime(timer) : "-- : -- : --";
    return (
        <div className="rounded-lg shadow-xl border-gray-300 border-2 relative flex items-center justify-between p-2 gap-2 cursor-not-allowed">
            <div className="left-container flex flex-col gap-2">
                <p className="text-xs font-bold">Go For Break</p>
                <p className="text-xs">{formattedBreakTime}</p>
                <p className="text-lg font-bold">{formattedTimer}</p>
                <p className="text-xs">Morning Shift</p>
            </div>
            <div className="right-container mr-8">
                <BreakIcon />
            </div>
            <div className="absolute inset-0 bg-gray-100 opacity-50 rounded-lg"></div>
        </div>
    )
}

export default BreakDisabledWidget;