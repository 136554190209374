import React, { forwardRef, useState } from "react";
import { InputSelect } from "@bluesilodev/timhutcomponents";
import { ChevronBottom, FiChevronUp } from "./Icon";

const CalendarMap = forwardRef(
  (
    {
      dataDate,
      handleRef,
      widthScroll,
      filteredData,
      handleClickFormShift,
      locationData,
      onChangeLocation,
      locationState,
      dataAssignShift,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(2);

    const handleShowData = () => {
      if (visible < 3) {
        setVisible(filteredData.length);
      }
    };

    const handleHiddenData = () => {
      if (visible >= 3) {
        setVisible(2);
      }
    };

    // GET THE LENGTH ARRAY SHIFT
    const totalLengthShift =
      filteredData.length > 0 &&
      filteredData.reduce((acc, obj) => {
        return obj.shiftData.length;
      }, 0);

    // AT LEAST ONE TRUE isShowBox TO SHOW EXPAND
    const isShowExpand =
      filteredData.length > 0 &&
      filteredData?.some((item) => {
        return (
          item.shiftData.length > 0 &&
          item.shiftData.some((shift) => shift.isShowBox === true)
        );
      });

    return (
      <div
        className={`flex border-b border-gray-400 ${filteredData.length < 2 && "min-h-[258px]"
          }`}
      >
        {/* ONLY ONE  */}
        <div className="flex flex-col border-r border-gray-400 flex-shrink-0  ">
          <div className="w-full h-[67px] rounded-tl-md flex items-center pl-3  border-b border-gray-400 ">
            <h1 className="font-semibold text-[14px]">Employee</h1>
          </div>

          <div className={`w-[400px] mt-14 flex items-center pl-3`}>
            <InputSelect
              options={locationData || []}
              title={"Location"}
              // value={locationData.length > 0 && locationData[1]?.value}
              value={locationState}
              classname={"w-[250px]"}
              // onChange={(e) => {
              //   setLocation(e.target.value);
              // }}
              onChange={onChangeLocation}
            />
          </div>
        </div>

        {/* MAPPING IN HERE */}

        <div
          className={`flex flex-col w-full overflow-hidden overflow-x-hidden`}
        >
          <div
            className={`min-w-[${widthScroll}px] overflow-hidden flex  ${dataDate.length > 0
              ? "min-h-[258px]"
              : "border-b border-gray-400 "
              }`}
            ref={ref}
            onScroll={handleRef}
          >
            {dataDate.length === 0 && (
              <div className=" border-gray-400 h-[91px] w-full"></div>
            )}

            <div className="flex ">
              {/* SHIFT DATA TEMPLATE  */}
              {filteredData.length > 0 &&
                filteredData.map((item, indexDate) => {
                  const dateShift = new Date(item?.date);

                  const dayShortName = dateShift.toLocaleDateString("en-US", {
                    weekday: "short",
                  });

                  const getDate = dateShift.getDate();
                  const getMonth = dateShift.getMonth() + 1;

                  return (
                    <div
                      className={`flex flex-col border-r border-gray-400  `}
                      key={indexDate}
                    >
                      <div
                        className={`w-[210.16px] h-[67px] border-b border-gray-400 rounded-tl-md flex flex-col justify-center items-center `}
                      >
                        <div>{dayShortName}</div>
                        <h1 className="font-semibold">
                          {getDate}/{getMonth}
                        </h1>
                      </div>

                      {item?.shiftData?.length === 0 && (
                        <div className="min-h-[191px]"></div>
                      )}

                      <div className="flex flex-col ">
                        {item.shiftData.length > 0 &&
                          item.shiftData.slice(0, visible).map((temp, idx) => {
                            // ADDING ALL EMPLOYEE NEEDED
                            const employeeNeeds = temp.employees && temp.employees.reduce(
                              (acc, current) => {
                                return acc + current.employeesNeeded;
                              },
                              0
                            );

                            const assignedCount = temp?.countLength || 0;

                            return (
                              <div
                                key={idx}
                                className={` w-[210px] flex flex-col gap-x-5 p-1`}
                              >
                                <div
                                  onClick={() =>
                                    handleClickFormShift(temp, temp?.uId)
                                  }
                                  style={{
                                    backgroundColor:
                                      temp?.isShowBox && temp?.color,
                                  }}
                                  className={`${temp?.isShowBox
                                    ? " w-full h-[74px] rounded-md cursor-pointer flex flex-col justify-center items-center text-[13px]"
                                    : "invisible"
                                    } `}
                                >
                                  <h1 className="font-semibold">
                                    {temp?.shiftName}
                                    {/* {temp.date.getDate()} */}
                                  </h1>
                                  <div>
                                    {temp?.startTime} - {temp?.endTime}{" "}
                                  </div>
                                  <div className="bg-white px-1 rounded-md">
                                    {assignedCount}/{employeeNeeds}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {totalLengthShift > 2 && visible < 3 && isShowExpand && (
            <div
              onClick={handleShowData}
              className="border-t border-gray-400 h-[27px]  flex justify-center items-center cursor-pointer "
            >
              <ChevronBottom color={"black"} className={"w-[24px] h-[24px]"} />
            </div>
          )}

          {totalLengthShift > 2 && visible >= 3 && isShowExpand && (
            <div
              onClick={handleHiddenData}
              className="border-t border-gray-400 h-[27px]  flex justify-center items-center cursor-pointer "
            >
              <FiChevronUp />
            </div>
          )}

          {dataDate.length === 0 && (
            <div className="border-b border-gray-400 h-full w-full"></div>
          )}
        </div>
      </div>
    );
  }
);

export default CalendarMap;


