import React from "react";

function HoverComponent({
  shift,
  baseOnJobposition,
  handleShowAssign,
  itemEmp,
  roleUser,
}) {
  const toAssignData = {
    itemEmp,
    shift: shift.toAssignShift,
  };

  return (
    <div
      //   key={index}
      onClick={() => handleShowAssign(toAssignData, itemEmp.uId)}
      className={`w-full h-full ${shift.atLeastOneTrue && baseOnJobposition.includes(true) && !roleUser
          ? "group hover:bg-gray-300 cursor-pointer flex justify-center items-center duration-300 transition-all"
          : "invisible"
        }`}
    >
      <h1 className=" group-hover:block hidden duration-300 transition-all text-gray-600">
        + Assign Shift
      </h1>
    </div>
  );
}

export default HoverComponent;
