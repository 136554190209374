import React, { useRef, useEffect, useState } from "react";
import CalendarMap from "./CalendarMap";
import EmployeeMap from "./EmployeeMap/index";
import { addDays, getDate, isBefore, isEqual } from "date-fns";
import PropTypes from "prop-types";
import { filter } from "lodash";

function TestShiftListBox({
    dataDate,
    shiftDataTemplate,
    dataAssignShift,
    handleClickFormShift,
    handleShowAssign,
    dataEmployees,
    setLocation,
    locationData,
    handleEditAssignShift,
    onChangeLocation,
    // DARI REDUX
    locationState,
    handleConflickAssign,
    roleUser,
}) {
    const ref1 = useRef();
    const ref2 = useRef([]);
    const [widthScroll, setWidthScroll] = useState(0);
    // const offsetValue = 400;

    // const handleScroll = (e) => {
    //     const scrollLeft = e.target.scrollLeft;

    //     ref1.current.scrollLeft = scrollLeft;

    //     // GET THE REF IS MUST BE ARRAY IN ARRAY
    //     ref2.current.forEach((refArray) => {
    //         refArray.forEach((ref) => {
    //             if (ref && ref.scrollLeft !== undefined) {
    //                 ref.scrollLeft = scrollLeft;
    //             }
    //         });
    //     });
    // };

    

    // useEffect(() => {
    //     if (ref1.current) {
    //         // Update the scrollWidth whenever children change
    //         console.log('current width', ref1.current.scrollWidth);
    //         let maxWidth = ref1.current.scrollWidth + offsetValue;
        
    //         setWidthScroll(maxWidth);
    //     }
    // }, [dataDate, widthScroll, shiftDataTemplate]);

    useEffect(() => {
        const updateScrollWidth = () => {
            if (ref1.current) {
                const maxWidth = ref1.current.scrollWidth
                console.log('max width', maxWidth);
                setWidthScroll(maxWidth);
            }
        };

        // Update scroll width on component mount and when data changes
        updateScrollWidth();

        // Recalculate scroll width on window resize (handles zoom changes)
        window.addEventListener('resize', ()=>{
            updateScrollWidth();
        });
        return () => {
            window.removeEventListener('resize', updateScrollWidth);
        };
    }, [dataDate, shiftDataTemplate]);

    const handleScroll = (e) => {
        const scrollLeft = e.target.scrollLeft;
    
        // Calculate based on the updated zoom-adjusted width
        const adjustedScrollLeft = Math.min(scrollLeft, widthScroll);
        ref1.current.scrollLeft = adjustedScrollLeft;
    
        ref2.current.forEach((refArray) => {
            refArray.forEach((ref) => {
                if (ref && ref.scrollLeft !== undefined) {
                    ref.scrollLeft = adjustedScrollLeft;
                }
            });
        });
    };


    function generateDateRange(startDate, endDate) {
        let dates = [];
        let currentDate = startDate;

        while (isBefore(currentDate, endDate) || isEqual(currentDate, endDate)) {
            dates.push(currentDate);
            currentDate = addDays(currentDate, 1);
        }

        return dates;
    }

    // GENERATE DATE FROM - TO
    const mapEveryDate =
        shiftDataTemplate.length > 0 &&
        shiftDataTemplate.map((item, idx) => {
            const newFrom = new Date(item.repeatDate.from);
            const newTo = new Date(item.repeatDate.to);
            const generateDate = generateDateRange(newFrom, newTo);

            return {
                ...item,
                repeatDate: generateDate,
            };
        });

    // MAP CHECK IF "daily", "weekly", "montyly"
    const filteredData =
        dataDate.length > 0 &&
        dataDate.map((date, indexDate) => {
            const shiftData =
                mapEveryDate.length > 0 &&
                mapEveryDate.map((shift, idxShift) => {
                    const repeatValue = shift.repeatBy.repeatValue;
                    // console.log('repeat value', repeatValue);
                    const repeatDate = shift.repeatDate;

                    if (shift.repeatBy.repeatType === "Weekly") {
                        const dayMapping = {
                            sunday: 0,
                            monday: 1,
                            tuesday: 2,
                            wednesday: 3,
                            thursday: 4,
                            friday: 5,
                            saturday: 6,
                        };

                        const filterByday = repeatDate.filter((repDate) => {
                            const dayOfWeek = repDate.getDay();
                            // console.log('day of week', dayOfWeek);

                            return (
                                repeatValue &&
                                repeatValue.map(day => day.toLowerCase()).includes(
                                    Object.keys(dayMapping).find(
                                        (key) => dayMapping[key] === dayOfWeek
                                    )
                                )
                            );
                        });


                        const isSameDay = (date1, date2) => {
                            return (
                                date1.getDate() === date2.getDate() &&
                                date1.getMonth() === date2.getMonth() &&
                                date1.getFullYear() === date2.getFullYear()
                            );
                        };

                        // console.log('filter by Day', filterByday);

                        // const boxStatus = filterByday.some(
                        //   (item) => item.getDate() === date.getDate()
                        // );
                        // const matchingDate = filterByday.find(
                        //     (item) => item.getTime() === date.getTime()
                        // );

                        const matchingDate = filterByday.find((item) => isSameDay(item, date));

                        console.log('matching date', matchingDate);

                        const boxStatus = Boolean(matchingDate);

                        return {
                            ...shift,
                            isShowBox: boxStatus,
                            date: matchingDate,
                        };
                    } else {
                        // Calculate the index of the date in the range of repeat dates
                        const indexInRange = repeatDate.findIndex(
                            (rep) => rep.getTime() === date.getTime()
                        );

                        // Determine if the date should be shown
                        const isShowBox =
                            indexInRange !== -1 &&
                            (indexInRange + 1) % (repeatValue + 1) !== 0;

                        return {
                            ...shift,
                            isShowBox: isShowBox,
                            date: date,
                        };
                    }
                });

            return {
                date,
                shiftData,
            };
        });

    // shiftId, organizationId, userId, date, jobPosition => assign model like this
    //
    // FOR EMPLOYEE MAP
    const employeeHover =
        filteredData.length > 0 &&
        filteredData.map((item) => {
            const atLeastOneTrue =
                item?.shiftData?.length > 0 &&
                item?.shiftData?.some((shift) => shift.isShowBox === true);

            // FILTER SHIFT DATA IF TRUE
            const shiftData =
                item?.shiftData?.length > 0 &&
                item?.shiftData?.filter((dateFil) => {
                    return dateFil.isShowBox === true;
                });

            return {
                date: item.date,
                shiftDetails: shiftData,
                atLeastOneTrue,
            };
        });

    // FOR COUNT TOTAL ASSIGN SHIFT
    const totalAssignShift =
        filteredData.length > 0 &&
        filteredData.map((item) => {
            const filterBaseOnAssignshift =
                item.shiftData.length > 0 &&
                item.shiftData.map((shift) => {
                    const shiftDate = new Date(shift.date);
                    const shiftNameShiftData = shift.shiftName;

                    const mapAssignShift = dataAssignShift.filter((assign) => {
                        const dateAssignShift = new Date(assign.date);
                        const assignName = assign.shiftDetails.shiftName;

                        return (
                            shiftDate.getDate() === dateAssignShift.getDate() &&
                            shift.isShowBox &&
                            shiftNameShiftData === assignName
                        );
                    });

                    return {
                        ...shift,
                        countLength: mapAssignShift.length,
                    };
                });

            return {
                ...item,
                shiftData: filterBaseOnAssignshift,
            };
        });

    console.log("dataAssign : ", totalAssignShift);
    // console.log("filtered shift : ", filteredData);

    return (
        <div>
            <div className="mt-5 w-full h-full">
                <div className="relative border-[1px] border-gray-400 w-full rounded-md flex flex-col select-none overflow-hidden">
                    {/* CALENDAR */}
                    <CalendarMap
                        dataDate={dataDate}
                        ref={ref1}
                        handleRef={handleScroll}
                        widthScroll={widthScroll}
                        // DATA SHIFT TEMPLATE
                        filteredData={totalAssignShift}
                        handleClickFormShift={handleClickFormShift}
                        setLocation={setLocation}
                        locationData={locationData}
                        onChangeLocation={onChangeLocation}
                        locationState={locationState}
                        dataAssignShift={dataAssignShift}
                    />

                    {/* EMPLOYEE */}
                    <div className="overflow-y-scroll h-[345px]">
                        <EmployeeMap
                            dataDate={dataDate}
                            handleRef={handleScroll}
                            ref={ref2}
                            widthScroll={widthScroll}
                            // DATA SHIFT TEMPLATE
                            filteredData={employeeHover}
                            dataAssignShift={dataAssignShift}
                            handleShowAssign={handleShowAssign}
                            dataEmployees={dataEmployees}
                            handleEditAssignShift={handleEditAssignShift}
                            locationState={locationState}
                            handleConflickAssign={handleConflickAssign}
                            roleUser={roleUser}
                        />
                    </div>
                    
                    {/* SCROLLBAR */}
                    {/* <div class="flex justify-end">
                            <div
                                className={`absolute bottom-[10px] w-3/5 h-[22px] z-30 overflow-x-auto ${dataDate.length === 0 && "hidden"}`}
                                onScroll={handleScroll}
                            >
                                <div
                                    style={{
                                        minWidth: widthScroll
                                    }}
                                />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default TestShiftListBox;

TestShiftListBox.propTypes = {
    dataDate: PropTypes.array,
    shiftDataTemplate: PropTypes.array,
    dataAssign: PropTypes.array,
    indexAssign: PropTypes.number,
    handleClickFormShift: PropTypes.func,
    handleShowAssign: PropTypes.func,
    setWidthScroll: PropTypes.func,
    widthScroll: PropTypes.number,
    dataEmployees: PropTypes.array,
    locationData: PropTypes.array,
};

TestShiftListBox.defaultProps = {
    dataDate: [],
    shiftDataTemplate: [],
    dataAssign: [],
    indexAssign: 0,
    handleClickFormShift: () => { },
    handleShowAssign: () => { },
    setWidthScroll: () => { },
    widthScroll: 0,
    dataEmployees: [],
    locationData: [],
};
