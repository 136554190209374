import axios from "axios";
import { useSelector } from "react-redux";
import { store } from "store"; // Adjust the path as per your actual project structure
import { setLogoutReducer } from "store/reducer/user";

export const getToken = () => localStorage.getItem("accessToken");

const useToken = () => {
  const token = useSelector(state => state.userData.token) || '';
  return token;
}

const getBearerToken = () => {
  const state = store.getState();
  const token = state.userData.token;
  return token ? `Bearer ${token}` : null;
}

// const getBearerToken = () => {
//   const state = store.getState();
//   let token = state.userData?.token;

//   if (!token) {
//     token = localStorage.getItem('accessToken'); // Fallback to localStorage if token is not in the store
//   }

//   return token ? `Bearer ${token}` : null;
// };

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

customAxios.interceptors.request.use(
  (request) => {
    const token = getBearerToken();
    if (token) {
      request.headers.Authorization = token;
      console.log("Bearer token set:", token);
    }
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response && [401, 403].includes(err.response.status)) {
      store.dispatch(setLogoutReducer());
      localStorage.removeItem("accessToken");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentRole");
      setTimeout(() => window.location.assign("/dashboard/login"), 100);
    }
    return Promise.reject(err);
  }
);

export { customAxios, useToken };

