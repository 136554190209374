import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { Header } from "@bluesilodev/timhutcomponents";

import { useGetAppQuery } from "services/commonAPI";
import { setLogoutReducer, setRoleReducer, setCurrentUserRole } from 'store/reducer/user';
import { handleChangeApp } from "utils/utils";

import { LogoTimhut } from "assets/icons";

const LayoutDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, currentRole, token } = useSelector((state) => state.userData);

  if (!currentUser) {
    navigate("/login");
  }

  if (currentUser?.role?.includes("SuperAdmin")) {
    window.location.href = '/employee/super/organization';
  }

  // dispatch(setCurrentUserRole({currentRole: ['Admin', 'Supervisor']}))

  console.log('current role', currentRole)

  const [apps, setApps] = useState([]);

  const { data: resApp, isSuccess: isSuccessApp } = useGetAppQuery();

  useEffect(() => {
    if (isSuccessApp && resApp.data) {
      setApps(resApp.data?.data);
    }
  }, [isSuccessApp, resApp]);

  useEffect(() => {
    // Update localStorage whenever currentRole changes
    if (currentRole) {
      localStorage.setItem("currentRole", currentRole);
    }
  }, [currentRole]);

  // Get currentUser from local storage
// const currentLocalUser = JSON.parse(localStorage.getItem("currentUser"));

// // Check if currentUser and role exist, and add "Supervisor" if not already present
// if (currentLocalUser && currentLocalUser.role && !currentLocalUser.role.includes("Supervisor")) {
//   currentLocalUser.role.push("Supervisor");

//   // Update local storage with modified currentUser object
//   localStorage.setItem("currentUser", JSON.stringify(currentLocalUser));
//   console.log('updated current user', currentLocalUser);
// }

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");
    navigate("/login");
  }

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  }

  const onClickApp = (link) => {
    handleChangeApp(link);
  }

  const onClickMyAccount = (link) => {
    handleChangeApp(`${link}/myAccount`);
  }

  return (
    <div className={`p-5`}>
      {
        apps.length > 0 && (
          <Header
            title={<LogoTimhut />}
            apps={apps}
            switchDisabled={currentUser?.role?.length < 2}
            userData={{
              name: currentUser?.userName,
              role: currentRole,
              language: "English",
              // switchRole: currentRole === "Admin" ? "User" : "Admin",
              switchRole:
                currentRole === "Admin"
                  ? (currentUser?.role.includes("Supervisor") ? "Supervisor" : "Employee")
                  : (currentUser?.role.includes("Admin") ? "Admin" : currentRole),
              image: currentUser?.photo?.length > 0 ? currentUser?.photo[0].link : "",
            }}
            onSwitch={onClickSwitch}
            onClickMyAccount={onClickMyAccount}
            onClickLogout={onClickLogout}
            onClickApp={onClickApp}
          />
        )
      }
      <Outlet />
    </div>
  )
};

export default LayoutDashboard;