import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';

import {
    Button,
    CheckBox,
    TextArea,
    InputSelect,
    InputText,
    InputDate,
    UploadImg1
} from '@bluesilodev/timhutcomponents';
import TestInputDate from 'components/testInputDate';
import { useGetLocationQuery } from "services/employeeAPI";
import { handlePostEvent } from 'services/employeeAPI';

import { validationSchema } from './schema';
import { alertSuccess, alertError } from 'utils/alert';

import EventIcon from 'assets/event.svg';

const EventForm = ({ onClose }) => {
    const [locationDataApi, setLocationDataApi] = useState([]);
    const [locationOption, setLocationOption] = useState([]);
    const [duration, setDuration] = useState(0); // Changed to numeric to store days duration
    const [eventDates, setEventDates] = useState({
        startDate: '',
        endDate: ''
    });

    const { data: resLocation, isSuccess: isSuccessLocation } = useGetLocationQuery();

    // Fetch locations and set options
    useEffect(() => {
        if (isSuccessLocation && resLocation?.data) {
            setLocationDataApi(resLocation.data.data || []);
            setLocationOption(
                resLocation.data.data.map(value => ({ label: value.locationName, value: value.locationName })) || []
            );
        }
    }, [isSuccessLocation, resLocation]);

 
    useEffect(() => {
        const { startDate, endDate } = eventDates;

        if (startDate && endDate) {
            const [startDay, startMonth, startYear] = startDate.split('/');
            const [endDay, endMonth, endYear] = endDate.split('/');

            const start = new Date(startYear, startMonth - 1, startDay);
            const end = new Date(endYear, endMonth - 1, endDay);

            const timeDiff = end.getTime() - start.getTime();
            if (timeDiff >= 0) {
                setDuration(timeDiff / (1000 * 3600 * 24)); // Set duration in days
            } else {
                setDuration(0); // Reset duration if dates are invalid
            }
        }
    }, [eventDates]);

    const eventlabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={EventIcon} alt="Event Icon" />
            <p className="text-sm">Create New Events</p>
        </div>
    )

    return (
        <Formik
            initialValues={{
                nameEvent: "",
                startDate: "",
                endDate: "",
                duration: 0,
                isCompanyHoliday: false,
                posterAttachment: "",
                eventNote: "",
                location: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                try {
                    const formData = new FormData();

                    const [startDay, startMonth, startYear] = values.startDate.split('/');
                    const [endDay, endMonth, endYear] = values.endDate.split('/');

                    // Handle two-digit year assumption by converting it to the correct century
                    const adjustedStartYear = startYear.length === 2 ? `20${startYear}` : startYear;
                    const adjustedEndYear = endYear.length === 2 ? `20${endYear}` : endYear;

                    // Create start and end dates correctly
                    const start = new Date(adjustedStartYear, startMonth - 1, startDay);
                    const end = new Date(adjustedEndYear, endMonth - 1, endDay);

                    // Append values to formData
                    for (const key in values) {
                        formData.append(key, values[key]);
                    }

                    formData.set('duration', duration); // Set duration from state
                    formData.set('startDate', start);
                    formData.set('endDate', end);

                    // Log form data contents
                    for (const pair of formData.entries()) {
                        console.log(`${pair[0]}: ${pair[1]}`);
                    }

                    const resp = await handlePostEvent(formData);
                    if (resp?.success === true && resp?.data && resp?.data?.data) {
                        console.log('Event submission successful');
                        alertSuccess('Success Sending Event Data');
                        onClose(); // Close the form or modal after successful submission
                    }
                } catch (error) {
                    console.error('Error during event submission:', error);
                    alertError(`Failed to send Event Data: ${error.response?.data?.message || ""}`);
                }
            }}
        >
            {({ handleChange, values, errors, touched, setFieldValue, handleBlur }) => {

                const changeFile = (e) => {
                    const file = e.currentTarget.files[0];
                    console.log('file set run')

                    if (file) {
                        // Use setFieldValue to update Formik state
                        setFieldValue("posterAttachment", file);
                    }
                };

                const handleDateChange = (field, date, setFieldValue) => {
                    setFieldValue(field, date);
                    setEventDates(prevState => ({
                        ...prevState,
                        [field]: date
                    })); 
                };

                return (
                    <Form>
                        <div className="form-group grid grid-cols-2 gap-6 p-3">
                            <div className="col-span-1">
                                <InputText
                                    name="nameEvent"
                                    title={"Name of Events"}
                                    value={values.nameEvent}
                                    required={true}
                                    label={null}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.nameEvent && touched.nameEvent && errors.nameEvent}
                                />
                            </div>
                            <div className="col-span-1">
                                <InputSelect
                                    name="location"
                                    title={"Locations"}
                                    options={locationOption}
                                    value={values.location}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.location && touched.location && errors.location}
                                />
                            </div>
                            <div className="col-span-1">
                                <TestInputDate
                                    name="startDate"
                                    label={"Start Date"}
                                    value={values.startDate}
                                    onChange={(date) => {
                                        console.log('Date selected:', date);
                                        handleDateChange("startDate", date, setFieldValue);
                                    }}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    errors={errors.startDate && touched.startDate && errors.startDate}
                                    required={true}
                                />
                            </div>
                            <div className="col-span-1">
                                <TestInputDate
                                    name="endDate"
                                    label={"End Date"}
                                    value={values.endDate}
                                    onChange={(date) => {
                                        console.log('Date selected:', date);
                                        handleDateChange("endDate", date, setFieldValue);
                                    }}
                                    onBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    errors={errors.endDate && touched.endDate && errors.endDate}
                                    required={true}
                                />
                            </div>
                            <div className="col-span-1">
                                <input
                                    name="duration"
                                    disabled={true}
                                    value={duration + " Day(s)"}
                                    className="bg-gray-200 w-full py-1 pl-4 rounded-md h-[60px] flex items-center"
                                />
                            </div>
                            <div className="col-span-1 flex items-center">
                                <div className="flex w-full items-center gap-3">
                                    <input
                                        name="isCompanyHoliday"
                                        type="checkbox"
                                        className={`w-5 h-5 text-indigo-600 rounded border-[1px] border-orange-500 cursor-pointer focus:ring-orange-600`}
                                        checked={values.isCompanyHoliday}
                                        onChange={() => {setFieldValue("isCompanyHoliday", !values.isCompanyHoliday) }}
                                    />
                                    <p>Is This Company Holiday?</p>
                                </div>
                            </div>
                            <div className="col-span-2">
                                <UploadImg1
                                    onChange={changeFile} // Call changeFile to handle file change
                                    textSizeLimit={"For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "}
                                    title={"Attachment"}
                                    onBlur={handleBlur}
                                    name="posterAttachment"
                                    accept="image/jpeg, image/jpg, image/png"
                                    message={errors.posterAttachment && touched.posterAttachment ? (
                                        <div className="text-red-500">{errors.posterAttachment}</div>
                                    ) : (
                                        values?.posterAttachment[0]?.name || values?.posterAttachment?.name
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-span-2 mt-4">
                            <TextArea
                                name="eventNote"
                                label={"Notes"}
                                rows={4}
                                value={values.eventNote}
                                onChange={handleChange}
                                error={touched.eventNote && errors.eventNote ? errors.eventNote : ''}
                            />
                        </div>
                        <div className="w-full grid gap-6 mt-4">
                            <Button type="submit" className="w-full h-[50px]" label={eventlabel} />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default EventForm;