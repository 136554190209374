import React from 'react';

const DataWidget = ({ attendanceDataStates }) => {
    // Destructure attendance data with default values if not available
    // const {
    //   name='',
    //   punchInData = '', 
    //   punchOutData = '', 
    //   breakData = '', 
    //   returnBreakData = ''
    // } = attendanceDataStates[currentShiftSchedulingId] || {};
    
    const {
        name='',
        punchInTime = '', 
        punchOutTime = '', 
        breakTime = '', 
        returnBreakTime = ''
      } = attendanceDataStates // Use optional chaining with fallback to empty object

    return (
      <div className='rounded-lg shadow-xl flex flex-col justify-center border-gray-300 border-2 p-2'>
        <div className="flex flex-col gap-1">
          <p className="text-xs font-bold">You currently have a shift: {name}</p>
          <p className="text-xs">Punch In Time: {punchInTime}</p> {/* Default to 'N/A' if empty */}
          <p className="text-xs">Break: {breakTime}</p>
          <p className="text-xs">Resume: {returnBreakTime}</p>
          <p className="text-xs">Punch Out Time: {punchOutTime}</p>
        </div>
      </div>
    );
  };

  export default DataWidget;