import React from "react";
import PropTypes from "prop-types";

const TestInputSelect = ({
    options,
    title,
    classname,
    disabled,
    required,
    error,
    value,
    onChange,
    ...props
  }) => {
    console.log('disabled', disabled)
    return (
      <div className={`w-full ${disabled && "bg-[#F2F4F5]"} select-none`}>
        <div className={`flex flex-col h-[60px] relative ${classname}`}>
          <label className="text-[14px] mt-2.5 ml-3.5 w-max" style={{ zIndex: 1 }}>
            {title} <span className="text-black">{required ? "*" : ""}</span>
          </label>
          <select
            {...props}
            className={`w-full outline-none h-full absolute cursor-pointer border-black ${
              !disabled && "border-[1px]"
            } rounded-md overflow-y-auto max-h-[200px] ${
              disabled ? "bg-[#F2F4F5]" : "bg-white"
            } text-black pt-5 pl-3.5 text-[14px] appearance-none`}
            disabled={disabled}
            value={value} // Use the value prop directly here
            onChange={onChange} // Pass the onChange handler here
          >
            <option className="w-full ml-2 text-[14px]" value="">
              Select an option
            </option>
            {options?.map((item, index) => (
              <option key={index} className="w-full text-[14px]" value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className="z-1 right-[10px] absolute top-[50%] -translate-y-2/4">
            <svg
              className={`w-[20px] h-[20px]`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              {...props}
            >
              <path
                d="M6 9L12 15L18 9"
                stroke={"black"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
  
        {error && <div className={`text-red-500`}>{error}</div>}
      </div>
    );
  };


  TestInputSelect.propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
    title: PropTypes.string,
    classname: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.node,
    value: PropTypes.string,
    onChange: PropTypes.func
  };
  
  TestInputSelect.defaultProps = {
    options: [],
    title: "Label",
    classname: "w-full ",
    disabled: false,
    required: false,
    error: null,
    value: null,
    onChange: () => {}
  };
  
  export default TestInputSelect;