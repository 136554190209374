import React from "react";
import { ConflictIcon, ReplacementSvg } from "../Icon";


function AssignShiftData({
    assignShift,
    handleEditAssignShift,
    employeeData,
    allShift,
    handleConflickAssign,
    isAnyConflick,
    roleUser,
}) {
    const shiftDetails = assignShift.shiftDetails;

    // console.log("DATA ASSIGN : ", assignShiftData);
    const combineShiftAndFilterData = {
        allShift: allShift,
        assignShift: assignShift,
        employee: employeeData,
    };

    return (
        // ASSIGN SHIFT
        <>
            {isAnyConflick === true && assignShift.conflickData.length > 0 ? (
                <div className="relative cursor-pointer h-[56px]">
                    <div
                        style={{
                            backgroundColor: shiftDetails.color,
                        }}
                        onClick={() =>
                            handleEditAssignShift(combineShiftAndFilterData, assignShift.uId)
                        }
                        className={`flex flex-col justify-center items-center h-[56px] w-full cursor-pointer rounded-md border-[1px]  ${!assignShift.isActive && "opacity-60"
                            }`}
                    >
                        <h1>{assignShift.jobPosition}</h1>
                        <p>
                            {shiftDetails.startTime}- {shiftDetails.endTime}
                        </p>
                    </div>

                    {assignShift.isReplacement ? (
                        <div className="absolute top-[-5px] right-[-5px]">
                            <ReplacementSvg />
                        </div>
                    ) : (
                        <div
                            className="absolute top-[-5px] right-[-5px]"
                            onClick={() => handleConflickAssign(assignShift)}
                        >
                            <ConflictIcon />
                        </div>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: shiftDetails.color,
                    }}
                    className={`flex flex-col justify-center items-center h-[56px] w-full cursor-pointer rounded-md border-[1px] relative ${roleUser && !assignShift.isActive && "hidden"
                        }  ${!assignShift.isActive && "opacity-60"}`}
                    onClick={() =>
                        handleEditAssignShift(combineShiftAndFilterData, assignShift.uId)
                    }
                >
                    <h1>
                        {assignShift.jobPosition} {/* {dateAssign.getDate()} */}
                    </h1>
                    <p>
                        {shiftDetails.startTime} - {shiftDetails.endTime}
                    </p>

                    {assignShift.isReplacement && (
                        <div className="absolute top-[-5px] right-[-5px]">
                            <ReplacementSvg />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default AssignShiftData;
