
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shiftTodayData: [],
    allowedTime: {},
    isEmptyShiftData : false,
    isInitialSetShiftData: true,
};

const shiftDataSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        setShiftTodayData: (state, action) => {
            state.shiftTodayData = action.payload;
        },
        setAllowedTimeData: (state, action) => {
            state.allowedTime = action.payload;
        },
        setIsEmptyShiftData: (state, action) => {
            state.isEmptyShiftData = action.payload;
        },
        setIsInitialSetShiftData: (state, action) => {
            state.isInitialSetShiftData = action.payload;
        },
        resetShiftDataState: (state) => {
            state.shiftTodayData = [];
            state.allowedTime = {}
            state.isEmptyShiftData = false;
            state.isInitialSetShiftData = true;
        }
    },
});

export const { setShiftTodayData, setAllowedTimeData, setIsEmptyShiftData, setIsInitialSetShiftData, resetShiftDataState } = shiftDataSlice.actions;
export default shiftDataSlice.reducer;