import React from "react";

export function ConflictIcon() {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.0687 10.175L20.9336 10.0262C20.2753 9.30088 19.8366 7.89786 19.9586 6.90762L19.9834 6.70622C20.1054 5.71598 19.4341 4.75613 18.4922 4.57197L18.3 4.53432C17.3581 4.35015 16.2106 3.48375 15.7501 2.60828L15.6565 2.43001C15.3267 1.80266 14.6762 1.43705 14.0158 1.4375C13.7539 1.4375 13.4908 1.49466 13.2451 1.61622L13.0691 1.70286C12.6351 1.91742 12.0627 2.02448 11.4907 2.02448C10.9208 2.02448 10.3514 1.91833 9.91879 1.70513L9.74279 1.61895C9.49751 1.49828 9.23524 1.44113 8.97428 1.44113C8.31251 1.44113 7.6612 1.8081 7.33184 2.4359L7.23904 2.61327C6.77985 3.48919 5.63319 4.35741 4.69129 4.54248L4.49873 4.58059C3.55683 4.76566 2.88678 5.72687 3.01007 6.71665L3.03534 6.91851C3.15864 7.90829 2.72123 9.31177 2.06382 10.0385L1.93007 10.1859C1.27266 10.9126 1.27353 12.1001 1.93138 12.825L2.06556 12.9733C2.72385 13.6987 3.16256 15.1017 3.04057 16.0919L3.01574 16.2933C2.89375 17.2836 3.56467 18.2439 4.50657 18.4276L4.69913 18.4657C5.64103 18.6494 6.78813 19.5163 7.24863 20.3917L7.34229 20.5695C7.67209 21.1969 8.32253 21.5625 8.98343 21.5625C9.24526 21.5625 9.5084 21.5049 9.75368 21.3838L9.92969 21.2971C10.3636 21.0826 10.9356 20.9755 11.5072 20.9755C12.0775 20.9755 12.6473 21.0821 13.08 21.2949L13.256 21.3815C13.5012 21.5022 13.7635 21.5593 14.0245 21.5593C14.6862 21.5593 15.3371 21.1924 15.6665 20.5646L15.7597 20.3858C16.2189 19.5099 17.3651 18.6417 18.307 18.4562L18.4991 18.4185C19.441 18.233 20.1111 17.2718 19.9878 16.282L19.9625 16.0806C19.8397 15.0908 20.2771 13.6873 20.9345 12.9611L21.0682 12.8132C21.7274 12.0879 21.727 10.8999 21.0687 10.175Z"
                fill="#B83C3C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4219 7.1875V12.9375H12.5781L12.5781 7.1875H10.4219ZM10.4219 14.375V16.1719H12.5781V14.375H10.4219Z"
                fill="#FDFDFD"
            />
        </svg>
    );
}

export function ChevronBottom({ color, className, ...props }) {
    return (
        <svg
            className={`${className}`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 9L12 15L18 9"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function FiChevronUp() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 15L12 9L6 15"
                stroke="#201140"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export function ReplacementSvg() {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.0687 10.175L20.9336 10.0262C20.2753 9.30088 19.8366 7.89786 19.9586 6.90762L19.9834 6.70622C20.1054 5.71598 19.4341 4.75613 18.4922 4.57197L18.3 4.53432C17.3581 4.35015 16.2106 3.48375 15.7501 2.60828L15.6565 2.43001C15.3267 1.80266 14.6762 1.43705 14.0158 1.4375C13.7539 1.4375 13.4908 1.49466 13.2451 1.61622L13.0691 1.70286C12.6351 1.91742 12.0627 2.02448 11.4907 2.02448C10.9208 2.02448 10.3514 1.91833 9.91879 1.70513L9.74279 1.61895C9.49751 1.49828 9.23524 1.44113 8.97428 1.44113C8.31251 1.44113 7.6612 1.8081 7.33184 2.4359L7.23904 2.61327C6.77985 3.48919 5.63319 4.35741 4.69129 4.54248L4.49873 4.58059C3.55683 4.76566 2.88678 5.72687 3.01007 6.71665L3.03534 6.91851C3.15864 7.90829 2.72123 9.31177 2.06382 10.0385L1.93007 10.1859C1.27266 10.9126 1.27353 12.1001 1.93138 12.825L2.06556 12.9733C2.72385 13.6987 3.16256 15.1017 3.04057 16.0919L3.01574 16.2933C2.89375 17.2836 3.56467 18.2439 4.50657 18.4276L4.69913 18.4657C5.64103 18.6494 6.78813 19.5163 7.24863 20.3917L7.34229 20.5695C7.67209 21.1969 8.32253 21.5625 8.98343 21.5625C9.24526 21.5625 9.5084 21.5049 9.75368 21.3838L9.92969 21.2971C10.3636 21.0826 10.9356 20.9755 11.5072 20.9755C12.0775 20.9755 12.6473 21.0821 13.08 21.2949L13.256 21.3815C13.5012 21.5022 13.7635 21.5593 14.0245 21.5593C14.6862 21.5593 15.3371 21.1924 15.6665 20.5646L15.7597 20.3858C16.2189 19.5099 17.3651 18.6417 18.307 18.4562L18.4991 18.4185C19.441 18.233 20.1111 17.2718 19.9878 16.282L19.9625 16.0806C19.8397 15.0908 20.2771 13.6873 20.9345 12.9611L21.0682 12.8132C21.7274 12.0879 21.727 10.8999 21.0687 10.175Z"
                fill="#B83C3C"
            />
            <g clipPath="url(#clip0_261_23642)">
                <path
                    d="M6.45825 15.3335V12.8335H9.20825"
                    stroke="#FDFDFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5417 8.6665V11.1665H13.7917"
                    stroke="#FDFDFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.60867 10.75C7.84112 10.1528 8.23619 9.61891 8.75701 9.19808C9.27783 8.77725 9.90742 8.48323 10.5871 8.34343C11.2667 8.20364 11.9742 8.22263 12.6435 8.39865C13.3129 8.57466 13.9223 8.90195 14.4149 9.34999L16.5416 11.1667M6.45825 12.8333L8.58492 14.65C9.07751 15.098 9.68692 15.4253 10.3563 15.6013C11.0257 15.7773 11.7332 15.7963 12.4128 15.6565C13.0924 15.5168 13.722 15.2227 14.2428 14.8019C14.7636 14.3811 15.1587 13.8472 15.3912 13.25"
                    stroke="#FDFDFD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_261_23642">
                    <rect
                        width="11"
                        height="10"
                        fill="white"
                        transform="translate(6 7)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

