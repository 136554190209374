import React, { useState, useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, Formik, Form } from 'formik';
import {
    Button,
    TextArea,
    InputSelect,
    InputText
} from '@bluesilodev/timhutcomponents';

import CameraComponent from 'components/camera';
import LocationComponent from 'components/locationTracking';
import TestInputSelect from 'components/testInputSelect';
import { handlePostPunchIn } from 'services/attendanceAPI';
import { useGetLocationQuery } from "services/employeeAPI";
import { useGetSchedulingShiftByUserID } from 'services/schedulingAPI';
import { setPunchInTime } from 'store/reducer/time';
import { processTimeData } from 'utils/utils';
import { extractPostalCode } from 'utils/utils';

import CloseIcon from 'assets/close.svg';
import TimeIcon from 'assets/time.svg';

import { validationSchema } from './schema';
import { alertSuccess, alertError } from 'utils/alert';
import { initial } from 'lodash';

const PunchInForm = ({ onClose, handleDataSubmit, attendanceFormData, shiftOptionList, handlePunchInFormSubmit }) => {
    const { currentUser } = useSelector((state) => state.userData);
    const [locationDataApi, setLocationDataApi] = useState([]);
    // const [currentValueLocation, setCurrentValueLocation] = useState('');
    const [shiftOption, setShiftOption] = useState([]);
    const [locationOption, setLocationOption] = useState([]);
    const [jobPositionOption, setJobPositionOption] = useState([]);
    const [locationData, setLocationData] = useState({});
    const dispatch = useDispatch();
    const { coords } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000,
    });
    const { data: resLocation, isSuccess: isSuccessLocation } = useGetLocationQuery();
    const { data: resScheduling, isSuccess: isSuccessScheduling } = useGetSchedulingShiftByUserID(currentUser.uId);


    useEffect(() => {
        if (isSuccessLocation && resLocation.data) {
            setLocationDataApi(resLocation.data.data || [])
            setLocationOption(resLocation.data.data.map((value) => ({ label: value.locationName, value: value.locationName })) || []);
            setJobPositionOption([]);
        }
    }, [isSuccessLocation, resLocation]);

    useEffect(() => {
        if (isSuccessScheduling && resScheduling?.assignShiftsByDate) {
            setShiftOption(resScheduling?.assignShiftsByDate?.[0]?.shifts.map((val) => ({ label: val.shift.shiftName, value: val.shift.uId })) || [])
        }
    }, [isSuccessScheduling, resScheduling]);


    // useEffect(() => {
    //     if (currentValueLocation) {
    //         const combinedData = locationDataApi.filter((val) => val.locationName === currentValueLocation).flatMap((location) => {
    //             return location.departments.flatMap((department) => department.jobPosition);
    //         });
    //         setJobPositionOption(combinedData.map((val) => ({ label: val, value: val })));
    //         return;
    //     }
    //     setJobPositionOption([]);
    // }, [currentValueLocation, attendanceFormData]);

    useEffect(() => {
        if (coords) {
            const fetchAddress = async () => {
                const { latitude, longitude } = coords;
                const apiKey = process.env.REACT_APP_TOM_TOM_API_KEY;
                const url = `https://api.tomtom.com/search/2/reverseGeocode/${latitude},${longitude}.json?key=${apiKey}`;

                try {
                    const response = await axios.get(url);
                    const addressData = response.data.addresses[0].address;
                    const address = addressData.freeformAddress;
                    const postalCode = addressData.postalCode || extractPostalCode(address);
                    setLocationData({
                        address: address,
                        lat: latitude,
                        long: longitude,
                        postalCode: postalCode,
                    });
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            };

            fetchAddress();
        }
    }, [coords]);

    // const { mutate, isLoading } = usePostPunchInDataMutation(handleFormSubmit, onClose, dispatch);

    const buttonLabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={TimeIcon} alt="Time Icon" />
            <p className="text-sm">Punch In</p>
        </div>
    );
    const cancellabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={CloseIcon} alt="Close Icon" />
            <p className="text-sm">Cancel</p>
        </div>
    )

    const initialValueId = shiftOptionList?.[0]?.value

    return (
        <Formik
            initialValues={{
                shift: shiftOptionList?.[0]?.value || '',
                location: attendanceFormData?.[initialValueId]?.location?.value || '', // Set initial locations
                jobPosition: attendanceFormData?.[initialValueId]?.jobPosition?.value || '', // Set initial jobPositions
                punchInDesc: '',
            }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const formData = new FormData();
            
            const shiftOptionValue = attendanceFormData?.shiftOption?.value 

            for (const key in values) {
                formData.append(key, values[key]);
                if (key === 'shift'){
                    formData.set('shift', shiftOptionValue); 
                }
            }
      
            formData.append('punchInGps', JSON.stringify(locationData));
      
            // Log form data contents
            for (const pair of formData.entries()) {
              console.log(`${pair[0]}: ${pair[1]}`);
            }
      
            const resp = await handlePostPunchIn(formData);
            if (resp?.data && resp?.data?.data?.punchIn) {
              const formattedPunchInTime = processTimeData(resp.data.data.punchIn);
              dispatch(setPunchInTime(formattedPunchInTime));
              handlePunchInFormSubmit(resp?.data?.data?.scheduleId)
              handleDataSubmit('punchInTime', formattedPunchInTime)
              console.log(`Formatted Punch In Time: ${formattedPunchInTime}`);
            }
            alertSuccess('Success Sending Employee Punch In Data');
            // handleFormSubmit();
            onClose();
          } catch (error) {
            console.error('Error during punch in submission:', error);
            alertError(`Failed to send Employee Punch In Data: ${error.response?.data?.message || ""}`);
          }
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => {

          // useEffect(() => {
          //   // Update location and jobPosition based on the selected shift
          //   if (attendanceFormData?.[values.shift]) {
          //     setFieldValue('location', attendanceFormData[values.shift].location?.value || '');
          //     setFieldValue('jobPosition', attendanceFormData[values.shift].jobPosition?.value || '');
          //   }
          // }, [values.shift, attendanceFormData, setFieldValue]);

          const handleShiftChange = (event) => {
            const selectedShift = event.target.value;
            setFieldValue('shift', selectedShift);
            
            // Update location and job position based on the selected shift
            const selectedShiftData = attendanceFormData?.[selectedShift];
            setFieldValue('location', selectedShiftData?.location?.value || '');
            setFieldValue('jobPosition', selectedShiftData?.jobPosition?.value || '');
            console.log('handle change called')
          };
    
          return (
            <Form>
              <div className="form-group grid grid-cols-2 gap-6 p-3">
                <div className="col-span-1">
                <TestInputSelect
                    name="shift"
                    title="Shift"
                    options={shiftOptionList && shiftOptionList.length > 0 ? shiftOptionList : []}
                    className="h-[20px]"
                    onChange={handleShiftChange}
                    value={values.shift}
                    label={null}
                    disabled={!shiftOptionList || shiftOptionList.length === 0}
                  />
                </div>
                <div className="col-span-1"></div>
                <div className="col-span-1">
                    <InputText
                        name="location"
                        title="Location"
                        className="h-[20px]"
                        onChange={handleChange}
                        value={values.location}
                        disabled
                        label={null}
                    />
                </div>
                <div className="col-span-1">
                    <InputText
                        name="jobPosition"
                        title="Job Position"
                        className="h-[20px]"
                        value={values.jobPosition}
                        onChange={handleChange}
                        disabled
                        label={null}
                    />
                </div>
                <div className="col-span-2">
                  <LocationComponent address={locationData.address} coords={coords} />
                </div>
                <div className="col-span-2 row-span-3">
                  <CameraComponent setFieldValue={setFieldValue} imageName="punchInImage" />
                </div>
              </div>
              <div className="col-span-2 mt-4">
                <TextArea
                  name="punchInDesc"
                  label="Description"
                  rows={4}
                  value={values.punchInDesc}
                  onChange={handleChange}
                  error={touched.punchInDesc && errors.punchInDesc ? errors.punchInDesc : ''}
                />
              </div>
              <div className="w-full grid grid-cols-2 gap-6 mt-4">
                <Button onClick={onClose} type="button" className="h-[50px]" label={cancellabel} />
                <Button type="submit" style="solid" className="h-[50px]" label={buttonLabel} />
              </div>
            </Form>
          );
        }}
      </Formik>
        
    );
};

export default PunchInForm


// const shiftOption = [
//     { label: 'Morning Shift', value: '1234' },
//     { label: 'Evening Shift', value: '3456' },
//   ];
  
//   const attendanceFormDataOption = {
//     1234: { jobPosition: 'Barista', location: 'Cafe A' },
//     3456: { jobPosition: 'Manager', location: 'Cafe B' },
//   };


// const PunchInForm = ({ onClose, handleDataSubmit, attendanceFormData }) => {
//     const { currentUser } = useSelector((state) => state.userData);
//     const [locationDataApi, setLocationDataApi] = useState([]);
//     // const [currentValueLocation, setCurrentValueLocation] = useState('');
//     const [shiftOption, setShiftOption] = useState([]);
//     const [locationOption, setLocationOption] = useState([{ label: 'Cafe Halim', value: 'Cafe Halim' }, { label: 'Cafe Halim#2', value: 'Cafe Halim#2' }]);
//     const [jobPositionOption, setJobPositionOption] = useState([{ label: 'Cafe Halim', value: 'Cafe Halim' }]);
//     const [locationData, setLocationData] = useState({});
//     const dispatch = useDispatch();
//     const { coords } = useGeolocated({
//         positionOptions: {
//             enableHighAccuracy: true,
//         },
//         userDecisionTimeout: 5000,
//     });
//     const { data: resLocation, isSuccess: isSuccessLocation } = useGetLocationQuery();
//     const { data: resScheduling, isSuccess: isSuccessScheduling } = useGetSchedulingShiftByUserID(currentUser.uId);


//     useEffect(() => {
//         if (isSuccessLocation && resLocation.data) {
//             setLocationDataApi(resLocation.data.data || [])
//             setLocationOption(resLocation.data.data.map((value) => ({ label: value.locationName, value: value.locationName })) || []);
//             setJobPositionOption([]);
//         }
//     }, [isSuccessLocation, resLocation]);

//     useEffect(() => {
//         if (isSuccessScheduling && resScheduling?.assignShiftsByDate) {
//             setShiftOption(resScheduling?.assignShiftsByDate?.[0]?.shifts.map((val) => ({ label: val.shift.shiftName, value: val.shift.uId })) || [])
//         }
//     }, [isSuccessScheduling, resScheduling]);


//     // useEffect(() => {
//     //     if (currentValueLocation) {
//     //         const combinedData = locationDataApi.filter((val) => val.locationName === currentValueLocation).flatMap((location) => {
//     //             return location.departments.flatMap((department) => department.jobPosition);
//     //         });
//     //         setJobPositionOption(combinedData.map((val) => ({ label: val, value: val })));
//     //         return;
//     //     }
//     //     setJobPositionOption([]);
//     // }, [currentValueLocation, attendanceFormData]);

//     useEffect(() => {
//         if (coords) {
//             const fetchAddress = async () => {
//                 const { latitude, longitude } = coords;
//                 const apiKey = process.env.REACT_APP_TOM_TOM_API_KEY;
//                 const url = `https://api.tomtom.com/search/2/reverseGeocode/${latitude},${longitude}.json?key=${apiKey}`;

//                 try {
//                     const response = await axios.get(url);
//                     const addressData = response.data.addresses[0].address;
//                     const address = addressData.freeformAddress;
//                     const postalCode = addressData.postalCode || extractPostalCode(address);
//                     setLocationData({
//                         address: address,
//                         lat: latitude,
//                         long: longitude,
//                         postalCode: postalCode,
//                     });
//                 } catch (error) {
//                     console.error('Error fetching address:', error);
//                 }
//             };

//             fetchAddress();
//         }
//     }, [coords]);

//     // const { mutate, isLoading } = usePostPunchInDataMutation(handleFormSubmit, onClose, dispatch);

//     const buttonLabel = (
//         <div className="flex w-full justify-center gap-2">
//             <img src={TimeIcon} alt="Time Icon" />
//             <p className="text-sm">Punch In</p>
//         </div>
//     );
//     const cancellabel = (
//         <div className="flex w-full justify-center gap-2">
//             <img src={CloseIcon} alt="Close Icon" />
//             <p className="text-sm">Cancel</p>
//         </div>
//     )

//     return (
//         <Formik
//             initialValues={{
//                 shift: selectedAttendanceFormData?.selectedShiftOption?.value || '',
//                 locations: selectedAttendanceFormData?.selectedLocationOption?.value || '',
//                 jobPositions: selectedAttendanceFormData?.selectedJobPositionOption?.value || '',
//                 punchInDesc: ''
//             }}
//         validationSchema={validationSchema}
//         onSubmit={async (values) => {
//           try {
//             const formData = new FormData();
//             for (const key in values) {
//               formData.append(key, values[key]);
//             }
      
//             formData.append('punchInGps', JSON.stringify(locationData));
      
//             // Log form data contents
//             for (const pair of formData.entries()) {
//               console.log(`${pair[0]}: ${pair[1]}`);
//             }
      
//             const resp = await handlePostPunchIn(formData);
//             if (resp.data && resp.data.data.punchIn) {
//               const formattedPunchInTime = processTimeData(resp.data.data.punchIn);
//               dispatch(setPunchInTime(formattedPunchInTime));
//               handleDataSubmit('punchInTime', formattedPunchInTime)
//               console.log(`Formatted Punch In Time: ${formattedPunchInTime}`);
//             }
//             alertSuccess('Success Sending Employee Punch In Data');
//             // handleFormSubmit();
//             onClose();
//           } catch (error) {
//             console.error('Error during punch in submission:', error);
//             alertError(`Failed to send Employee Punch In Data: ${error.response?.data?.message || ""}`);
//           }
//         }}
//       >
//         {({ handleChange, values, errors, touched, setFieldValue }) => {
    
//           return (
//             <Form>
//               <div className="form-group grid grid-cols-2 gap-6 p-3">
//                 <div className="col-span-1">
//                   <InputSelect
//                     name="shift"
//                     title="Shift"
//                     options={attendanceFormDataOption.shiftOption}
//                     className="h-[20px]"
//                     required
//                     value={values.shift}
//                     onChange={(e) => {
//                         setCurrentShiftSchedulingId(e.target.value); // Update your location value
//                         handleChange(e); // Make sure to call Formik's handleChange
//                       }}
//                     error={touched.shift && errors.shift ? errors.shift : ''}
//                   />
//                 </div>
//                 <div className="col-span-1"></div>
//                 <div className="col-span-1">
//                   {/* <InputSelect
//                     name="locations"
//                     title="Locations"
//                     options={attendanceFormDataOption.locationOption}
//                     className="h-[20px]"
//                     required
//                     value={values.locations}
//                     onChange={handleChange}
//                     error={touched.locations && errors.locations ? errors.locations : ''}
//                   /> */}
//                   <InputText
//                     name="locations"
//                     title="Location"
//                     onChange={handleChange}
//                     value={values.locations}
//                     required
//                     disabled
//                     label={null}
//                     />
//                 </div>
//                 <div className="col-span-1">
//                   <InputSelect
//                     name="jobPositions"
//                     title="Job Positions"
//                     options={attendanceFormDataOption.jobPositionOption}
//                     className="h-[20px]"
//                     required
//                     value={values.jobPositions}
//                     onChange={handleChange}
//                     error={touched.jobPositions && errors.jobPositions ? errors.jobPositions : ''}
//                   />
//                 </div>
//                 <div className="col-span-2">
//                   <LocationComponent address={locationData.address} coords={coords} />
//                 </div>
//                 <div className="col-span-2 row-span-3">
//                   <CameraComponent setFieldValue={setFieldValue} imageName="punchInImage" />
//                 </div>
//               </div>
//               <div className="col-span-2 mt-4">
//                 <TextArea
//                   name="punchInDesc"
//                   label="Description"
//                   rows={4}
//                   value={values.punchInDesc}
//                   onChange={handleChange}
//                   error={touched.punchInDesc && errors.punchInDesc ? errors.punchInDesc : ''}
//                 />
//               </div>
//               <div className="w-full grid grid-cols-2 gap-6 mt-4">
//                 <Button onClick={onClose} type="button" className="h-[50px]" label={cancellabel} />
//                 <Button type="submit" style="solid" className="h-[50px]" label={buttonLabel} />
//               </div>
//             </Form>
//           );
//         }}
//       </Formik>
        
//     );
// };

// export default PunchInForm;