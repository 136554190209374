import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { enGB } from 'date-fns/locale';
// import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import format from "date-fns/format";

const TestInputDateRange = ({
  label,
  name,
  width,
  setFieldValue,
  disabled,
  required,
  error,
  ...props
}) => {
  const [currentWeek, setCurrentWeek] = useState(getCurrentWeekDates());
  const [range, setRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }]);
  const wrapperRef = useRef(null);


  function getCurrentWeekDates() {
    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    const endOfWeek = new Date(now.setDate(startOfWeek.getDate() + 6));
    return { start: startOfWeek, end: endOfWeek };
  }

  const handleWeekChange = (direction) => {
    const newStartDate = new Date(currentWeek.start);
    newStartDate.setDate(currentWeek.start.getDate() + (direction === 'left' ? -7 : 7));
    const newEndDate = new Date(currentWeek.end);
    newEndDate.setDate(newStartDate.getDate() + 6);

    setCurrentWeek({ start: newStartDate, end: newEndDate });
    setFieldValue(name, [newStartDate, newEndDate]);
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const weekNumber = getWeekNumber(currentWeek.start);

  const dateFormat = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    return { month, day };
  };

  const start = dateFormat(currentWeek.start);
  const end = dateFormat(currentWeek.end);


  return (
    <div
      className={`h-[60px] w-full relative cursor-pointer ${disabled ? "bg-gray-200 rounded-md" : ""}`}
      style={{ width: width }}
      ref={wrapperRef}
    >
      <div className="flex justify-between items-center mt-2">
        <button type="button" onClick={() => handleWeekChange('left')} disabled={disabled}>
          <span>&lt;</span>
        </button>
        
        <span className="text-[10px] mx-6">
          {`${start.month} ${start.day}-${end.day} (Week ${weekNumber})`}
        </span>
        
        <button type="button" onClick={() => handleWeekChange('right')} disabled={disabled}>
          <span>&gt;</span>
        </button>
      </div>
      
    </div>
  );
};

TestInputDateRange.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  width: PropTypes.string,
  setFieldValue: PropTypes.func,
  error: PropTypes.any,
};

TestInputDateRange.defaultProps = {
  label: "Date",
  name: "",
  disabled: false,
  required: false,
  width: "full",
  setFieldValue: () => {},
  error: null,
};


const TestInputWeekPicker = ({ name, setFetchDataDateRange, disabled }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [weekRange, setWeekRange] = useState({ start: new Date(), end: new Date() });
    const [dropdownActive, setDropdownActive] = useState(false);
  
    // Function to get the start and end date of the selected week
    const handleWeekChange = (date) => {
      const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
  
      setWeekRange({ start: startOfWeek, end: endOfWeek });
      setStartDate(startOfWeek);
      setDropdownActive(false);
      setFetchDataDateRange({startDate:startOfWeek, endDate:endOfWeek});
    };
  
    // Format date to display in the span
    const formatDate = (date) => {
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      return `${month} ${day}`;
    };
  
    // Week number function
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };
  
    // Week number of the current range
    const weekNumber = getWeekNumber(weekRange.start);
  
    // Create an array of dates for the selected week
    const getHighlightedDates = () => {
      const dates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(weekRange.start);
        date.setDate(date.getDate() + i);
        dates.push(date);
      }
      return dates;
    };

    console.log('week range', weekRange);
  
    return (
      <div className="relative">
        <div className="flex justify-center items-center"
        >
          {/* Left Arrow Button */}
          <button
            type="button"
            onClick={() => handleWeekChange(new Date(startDate.setDate(startDate.getDate() - 7)))}
            disabled={disabled}
          >
            <span className="text-lg">&lt;</span>
          </button>
  
          {/* Week picker span */}
          <span className="flex items-end text-[12px] mx-4 cursor-pointer" onClick={() => setDropdownActive(!dropdownActive)}>
            {`${formatDate(weekRange.start)} - ${formatDate(weekRange.end)} (Week ${weekNumber})`}
          </span>
  
          {/* Right Arrow Button */}
          <button
            type="button"
            onClick={() => handleWeekChange(new Date(startDate.setDate(startDate.getDate() + 7)))}
            disabled={disabled}
          >
            <span className="text-lg">&gt;</span>
          </button>
        </div>
  
        {/* Week Picker Dropdown */}
        {dropdownActive && (
          <div className="absolute top-[100%] left-[-15%] mt-2 z-50 shadow-md bg-white border">
            <DatePicker
              name={name}
              selected={startDate}
              onChange={handleWeekChange}
              showWeekNumbers
              showPopperArrow={false}
              inline
              dateFormat="MM/dd/yyyy"
              highlightDates={getHighlightedDates()} // Highlight the selected week
              disabled={disabled}
              selectsStart
              shouldCloseOnSelect={true}
              dayClassName={(date) => 
                date >= weekRange.start && date <= weekRange.end 
                  ? "highlighted-day" // Apply a custom class for highlighted days
                  : undefined
              }
            />
          </div>
        )}
      </div>
    );
  };
  
//   export default TestInputWeekPicker;



const calculateDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateArray = [];

    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };

  const InputDateWeekPickerRange = ({ name, setFetchDataDateRange, setFieldValue, disabled, width }) => {
    const [startDate, setStartDate] = useState(new Date());
    const [weekRange, setWeekRange] = useState({ start: new Date(), end: new Date() });
    const [dropdownActive, setDropdownActive] = useState(false);
    const wrapperRef = useRef(null);
  
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDropdownActive(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const handleWeekChange = (date) => {
      console.log('handleWeekChange triggered');
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Adjust to the start of the week
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Adjust to the end of the week
  
      const dateValues = calculateDateRange(startOfWeek, endOfWeek);
      const dateValuesRange = dateValues.map(dateStr => new Date(dateStr));
      console.log('date values range', dateValuesRange);
  
      setWeekRange({ start: startOfWeek, end: endOfWeek });
      setStartDate(new Date(startOfWeek)); // Ensure a new Date object is created
      setDropdownActive(false);
      setFetchDataDateRange({ startDate: startOfWeek, endDate: endOfWeek });
  
      setFieldValue(name, dateValuesRange); // Update the form field
    };
  
    const formatDate = (date) => {
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      return `${month} ${day}`;
    };
  
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };
  
    const weekNumber = getWeekNumber(weekRange.start);
  
    const getHighlightedDates = () => {
      const dates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date(weekRange.start);
        date.setDate(date.getDate() + i);
        dates.push(date);
      }
      return dates;
    };
  
    return (
      <div className="relative w-full h-[60px]"
      ref={wrapperRef}
      >
        <div 
          className="w-full h-full px cursor-pointer border-[1px] border-black flex justify-between items-center p-2"
          style={{ width }}
        >
          {/* Previous Week Button */}
          <button
            type="button"
            onClick={() => handleWeekChange(new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000))}
            disabled={disabled}
          >
            <span className="text-lg">&lt;</span>
          </button>
  
          {/* Week Range Display */}
          <span className="flex items-end text-[12px] mx-4 cursor-pointer" onClick={() => setDropdownActive(!dropdownActive)}>
            {`${formatDate(weekRange.start)} - ${formatDate(weekRange.end)} (Week ${weekNumber})`}
          </span>
  
          {/* Next Week Button */}
          <button
            type="button"
            onClick={() => handleWeekChange(new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000))}
            disabled={disabled}
          >
            <span className="text-lg">&gt;</span>
          </button>
        </div>
  
        {/* Date Picker Dropdown */}
        {dropdownActive && (
          <div className="absolute top-[100%] left-0 mt-2 z-50 shadow-md bg-white border">
            <DatePicker
              name={name}
              selected={startDate}
              onChange={handleWeekChange}
              showWeekNumbers
              showPopperArrow={false}
              inline
              dateFormat="MM/dd/yyyy"
              highlightDates={getHighlightedDates()}
              disabled={disabled}
              selectsStart
              shouldCloseOnSelect={true}
              dayClassName={(date) =>
                date >= weekRange.start && date <= weekRange.end
                  ? "highlighted-day"
                  : undefined
              }
            />
          </div>
        )}
      </div>
    );
  };

InputDateWeekPickerRange.propTypes = {
  name: PropTypes.string, // name for the input
  setFetchDataDateRange: PropTypes.func.isRequired, // callback to set the date range
  disabled: PropTypes.bool, // whether the input is disabled
};

InputDateWeekPickerRange.defaultProps = {
  name: '', // default to an empty string for the name
  disabled: false, // default to false for disabled
};


export {TestInputWeekPicker,TestInputDateRange, InputDateWeekPickerRange};