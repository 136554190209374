import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TestModalDialog from "components/testModalDialog";
import {
    PunchInWidget,
    PunchOutWidget,
    PunchDisabledWidget,
    BreakWidget,
    ReturnBreakWidget,
    BreakDisabledWidget,
    DataWidget
} from "pages/user/widgets";
import Tabs from "components/tabs";
import PunchInForm from "components/modal/punchInModal";
import PunchOutForm from "components/modal/punchOutModal";
import BreakForm from "components/modal/breakModal";
import ReturnBreakForm from "components/modal/returnBreakModal";
import CustomCalendar from "components/calendar";
import { fetchAndSetWidgets } from "services/attendanceAPI";
import { 
    handleSettingWidgetState, 
    isValidTime, 
    processTimeData, 
    getAndSetAllowedTime,
    getFromLocalStorage,
    saveToLocalStorage,
    formatMonthYear
} from "utils/utils";
import {
    getInitialIsTimerRunning,
    getInitialTimers,
    getCurrentDate,
    initializeCurrentDate,
    getInitialAttendanceDataStates,
} from "utils/utils";
import { setWidgetState } from "store/reducer/widget";
import { setShiftTodayData, setIsEmptyShiftData, setIsInitialSetShiftData, setAllowedTimeData, resetShiftDataState } from "store/reducer/shift";
import { 
    useGetShiftQuery,
    useGetSchedulingShiftByUserID, 
    useGetLocationQuery, 
    getAttendanceByShift 
} from "services/schedulingAPI";
import { useGetAttendanceByUser, useGetAttendanceSettingByUser } from "services/attendanceAPI";
import { alertSuccess, alertError } from "utils/alert";
import { initial } from "lodash";

const EmployeeDashboardPage = ({ currentUserName, setDateData, exampleOptions, day, currentMonth }) => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.userData);
    const { currentUser: loggedUser } = useSelector((state) => state.userData);
    const widgetStates = useSelector(state => state.widgetStateData);
  
    const [attendanceDataStates, setAttendanceDataStates] = useState(() => 
        getFromLocalStorage('attendanceDataStates', {  id: '',
        name: '',
        punchInTime: '',
        punchOutTime: '',
        breakTime: '',
        returnBreakTime: '',
    })
    );

    const [modalShow, setModalShow] = useState({
        punchModal: false,
        breakModal: false,
        punchOutModal: false,
        returnBreakModal: false
    });
    // set fetch trigger variable boolean to trigger refetching data based on state 
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [timers, setTimers] = useState(getInitialTimers);
    const [isTimerRunning, setIsTimerRunning] = useState(getInitialIsTimerRunning);
    const [locationDataApi, setLocationDataApi] = useState([]);
    const [shiftDataApi, setShiftDataApi] = useState([]);
    const {
        shiftTodayData,
        isEmptyShiftData,
        isInitialSetShiftData,
        allowedTime
      } = useSelector((state) => state.shiftData);
    // const [shiftTodayData, setShiftTodayData] = useState(() => {
    //     // Initialize from local storage
    //     const savedData = localStorage.getItem('shiftTodayData');
    //     return savedData ? JSON.parse(savedData) : [];
    // });
    const [allAssignShiftData, setAllAssignShiftData] = useState([]);
    const [latestAttendanceData, setlatestAttendanceData] = useState([]);
    const [attendanceUserSetting, setAttendanceUserSetting] = useState([]);
    // const [allowedTime, setAllowedTime] = useState({});
    // const [emptyShiftData, setEmptyShiftData] = useState(false);
    const [toBeCompletedShiftId, setToBeCompletedShiftId] = useState('');
    const [currentShiftSchedulingId, setCurrentShiftSchedulingId] = useState('');
    const [attendanceFormData, setAttendanceFormData] = useState(() => 
        getFromLocalStorage('attendanceFormData', {} )
    );
    const [shiftOptionList, setShiftOptionList] = useState([])
    const [isLatestToPunchOut, setIsLatestToPunchOut] = useState(false);

    const { data: resLocation, isSuccess: isSuccessLocation } = useGetLocationQuery();
    const { data: resAllShift, isSuccess: isSuccessAllShift } = useGetShiftQuery();
    const { data: resScheduling, isSuccess: isSuccessScheduling } = useGetSchedulingShiftByUserID(loggedUser?.uId || "");
    const { data: resAttendance, isSuccess: isSuccessAttendance } = useGetAttendanceByUser();
    const { data: resAttendanceSetting, isSuccess: isSuccessAttendanceSetting } = useGetAttendanceSettingByUser();
  
    // Effect to check for a new day and reset state if necessary
    useEffect(() => {
        const currentDate = new Date().toDateString(); // Get today's date as a string
        const savedDate = localStorage.getItem('lastShiftUpdateDate') || ''; 

        // If it's a new day, reset the state
        if (savedDate !== currentDate) {
            console.log('New day detected, resetting state');
            dispatch(resetShiftDataState());
            localStorage.setItem('attendanceDataStates', {
                id: '',
                name: '',
                punchInTime: '',
                punchOutTime: '',
                breakTime: '',
                returnBreakTime: '',
            });
            localStorage.setItem('attendanceFormData', {});
            localStorage.setItem('lastShiftUpdateDate', currentDate); // Update localStorage with today's date
        }
    }, [dispatch]); // Runs once on mount and every time the date changes

  
    // Fetch location data
    useEffect(() => {
        if (isSuccessLocation && resLocation?.data) {
            // Set location data
            setLocationDataApi(resLocation.data.data || []); // Store full location data for later use
        }
    }, [isSuccessLocation, resLocation]);

    // Fetch All Shift data
    useEffect(() => {
        if (isSuccessAllShift && resAllShift?.data?.data) {
            // Set Shift Data
            // console.log('all shift data', resAllShift);
            setShiftDataApi(resAllShift.data.data || []); // Store full location data for later use
        }
    }, [isSuccessAllShift, resAllShift]);

    useEffect(() => {
        if (isSuccessAttendance && resAttendance?.data) {
        //   console.log('latest attendance data punch out exists:', resAttendance.data.latestData);
          setlatestAttendanceData(resAttendance.data.latestData || []);
        }
      }, [isSuccessAttendance, resAttendance]);

    useEffect(() => {
        if (isSuccessAttendanceSetting && resAttendanceSetting?.data) {
            //  console.log('attendance settings', resAttendanceSetting)
             setAttendanceUserSetting(resAttendanceSetting.data.data || []);
              
        }
    }, [isSuccessAttendanceSetting, resAttendanceSetting]);
    

    useEffect(() => {
        
        // Only set shiftTodayData if it is empty
        if (isSuccessScheduling && resScheduling?.assignShiftsByDate) {
            console.log('all assign shift data', resScheduling?.assignShiftsByDate);
            setAllAssignShiftData(resScheduling?.assignShiftsByDate);
            // Check if initialShiftTodayData is empty
            if (resScheduling?.assignShiftsByDate?.length > 0 && isInitialSetShiftData) {
                // If it's empty, set the data from the response
                // console.log('setting first shift today data run');
                dispatch(setShiftTodayData(resScheduling?.assignShiftsByDate[0] || []));
                dispatch(setIsInitialSetShiftData(false))
            } else if (resScheduling?.assignShiftsByDate?.length === 0) {
                // Optionally log or handle the case where the data is already present
                console.log('today shift data empty');
                dispatch(setIsEmptyShiftData(true))
            }
        }
    }, [isSuccessScheduling, resScheduling]);
    
    const settingFormOption = () => {
        setShiftOptionList(
            shiftTodayData?.shifts?.map((value) => ({ label: value.shift?.shiftName, value: value.shift?.uId })) || []
        );
    
        shiftTodayData?.shifts?.forEach((shift) => {
            const jobPosition = shift.jobPosition;
            const locationId = shift?.shift?.locationId;
    
            // Find the location matching the locationId in locationDataApi
            const matchedLocation = locationDataApi?.find(
                (location) => location._id === locationId
            );

            const matchedLocationName = matchedLocation ? matchedLocation.locationName : '';
    
            // Update attendanceFormData incrementally
            setAttendanceFormData((prevData) => ({
                ...prevData,
                [shift.shiftId]: {
                    jobPosition: {
                        label: jobPosition,
                        value: jobPosition,
                    },
                    location: {
                        label: matchedLocationName,
                        value: matchedLocationName,
                    },
                },
            }));
        });
    };

    const handleDispatchAllowedTime = (data) =>{
        dispatch(setAllowedTimeData(data))
    }

    useEffect(() => {
        // Check if the latest attendance data exists and if it does not have a punch-out
        const latestAttendanceNoPunchOut = Array.isArray(latestAttendanceData) && latestAttendanceData.length > 0 ? 
        !latestAttendanceData.punchOut : false;
    
        console.log('latest attendance check this run', latestAttendanceNoPunchOut);
    
        // If there is no punch-out and attendance data is available
        if (latestAttendanceNoPunchOut) {
            console.log('what about this')
            setToBeCompletedShiftId(latestAttendanceData.scheduleID);
            setIsLatestToPunchOut(true);
        } else {
            // Check if shiftTodayData is empty and set emptyShiftData to true
            // if (shiftTodayData && shiftTodayData?.length === 0) {
            //     // console.log('empty setting boolean run');
            //     dispatch(setIsEmptyShiftData(true));
            //     return; // Early return if there are no shifts
            // }
    
            // If punch-out exists or there are shifts today
            if (shiftTodayData?.shifts?.length > 0) {
                // console.log('setting latest shift scheduling id today data run', shiftTodayData.shifts[0]?.shift?.uId);
                setToBeCompletedShiftId('');
                setCurrentShiftSchedulingId(shiftTodayData.shifts[0]?.shift?.uId);
                settingFormOption()
                setIsLatestToPunchOut(false);  // Track state properly
                console.log('what run')

                if(Object.entries(allowedTime)?.length === 0){
                    console.log('allowed time setting run?')
                    const matchedShift = shiftDataApi?.find((shift) => shift.uId === shiftTodayData.shifts[0]?.shift?.uId);
                    getAndSetAllowedTime(matchedShift, handleDispatchAllowedTime, attendanceUserSetting);
                }
            }
            else {
                dispatch(setIsEmptyShiftData(true))
                console.log('which run')
            }
        }
    }, [latestAttendanceData, shiftTodayData, allowedTime]);

    // useEffect(() => {
    //     if (currentShiftSchedulingId && currentShiftSchedulingId !== '') {
    //         // console.log('current shift scheduling run');

    //         const matchedShift = shiftDataApi?.find((shift) => shift.uId === currentShiftSchedulingId);
            
    //         getAndSetAllowedTime(matchedShift, setAllowedTime, attendanceUserSetting);

            
    //         const storedAttendanceData = JSON.parse(localStorage.getItem('attendanceDataStates'));
           
    //         // If data exists and matches currentShiftSchedulingId, bypass setting form data
    //         if (storedAttendanceData && storedAttendanceData.id === currentShiftSchedulingId) {
    //             // console.log('Attendance data already in local storage, bypassing form state update.');
    //             return; // Skip the update
    //         }

    //         // const matchedShift = shiftDataApi?.find((shift) => shift.uId === currentShiftSchedulingId);
    
    //         // Initialize updatedAttendanceFormData
    //         const updatedAttendanceFormData = {};
    //         const updatedAttendanceDataState = {};
    
    //         if (matchedShift) {
    //             // Create shiftOption from matchedShift, fallback to empty string if undefined
    //             const shiftOption = {
    //                 label: matchedShift.shiftName || '', // Fallback to empty string
    //                 value: matchedShift.uId || '', // Fallback to empty string
    //             };
    
    //             // Now get jobPosition from shiftTodayData instead of matchedShift
    //             const jobPositionShift = shiftTodayData?.shifts?.find(shift => shift.shiftId === currentShiftSchedulingId);

    //             const jobPosition = {
    //                 label: jobPositionShift?.jobPosition || '', // Fallback to empty string
    //                 value: jobPositionShift?.jobPosition || '', // Fallback to empty string
    //             };
    
    //             // Find the matching location by locationId from locationDataApi
    //             const matchedLocation = locationDataApi?.find(location => location._id === matchedShift.locationId);
    //             const location = {
    //                 label: matchedLocation?.locationName || '', // Fallback to empty string
    //                 value: matchedLocation?.locationName || '', // Fallback to empty string
    //             };
    
    //             // Add shiftOption, jobPosition, and location to updatedAttendanceFormData
    //             updatedAttendanceFormData.shiftOption = shiftOption;
    //             updatedAttendanceFormData.jobPosition = jobPosition;
    //             updatedAttendanceFormData.location = location;
    
    //             // Set attendance form data with the updated information
    //             setAttendanceFormData(updatedAttendanceFormData);
    
    //             updatedAttendanceDataState.id = '';
    //             updatedAttendanceDataState.name = '';
    //             updatedAttendanceDataState.punchInTime = '';
    //             updatedAttendanceDataState.punchOutTime = '';
    //             updatedAttendanceDataState.breakTime = '';
    //             updatedAttendanceDataState.returnBreakTime = '';
    
    //             // Set attendanceDataState with the initialized values
    //             setAttendanceDataStates(updatedAttendanceDataState);
    //         }
    
            
    //     }
    // }, [currentShiftSchedulingId, shiftDataApi, locationDataApi, shiftTodayData]);


    useEffect(() => {
        if (toBeCompletedShiftId && toBeCompletedShiftId !== '') {
            console.log('to be completed shift scheduling run');
            // Check localStorage for existing attendanceDataState
            const storedAttendanceData = JSON.parse(localStorage.getItem('attendanceDataStates'));
           
            // If data exists and matches currentShiftSchedulingId, bypass setting form data
            if (storedAttendanceData && storedAttendanceData.id === toBeCompletedShiftId) {
                console.log('Attendance data already in local storage, bypassing form state update.');
                return; // Skip the update
            }

            const matchedShift = shiftDataApi?.find((shift) => shift.uId === toBeCompletedShiftId);
            console.log('matched shift to be completed', toBeCompletedShiftId)
            // Initialize updatedAttendanceFormData
            const updatedAttendanceFormData = {};
            const updatedAttendanceDataState = {};
    
            if (matchedShift) {
                // Create shiftOption from matchedShift, fallback to empty string if undefined
                const shiftOption = {
                    label: matchedShift.shiftName || '', // Fallback to empty string
                    value: matchedShift.uId || '', // Fallback to empty string
                };
    
                   // Fetch job position from allAssignShiftData based on the shiftId
                const matchedAssignShift = allAssignShiftData
                .flatMap(assign => assign.shifts) // Flatten the shifts array
                .find(shift => shift.shiftId === matchedShift.shiftId); // Match with the shiftId
            

                const jobPosition = {
                    label: matchedAssignShift?.jobPosition || '', // Fallback to empty string
                    value: matchedAssignShift?.jobPosition || '', // Fallback to empty string
                };
    
                // Find the matching location by locationId from locationDataApi
                const matchedLocation = locationDataApi?.find(location => location._id === matchedShift.locationId);
                const location = {
                    label: matchedLocation?.locationName || '', // Fallback to empty string
                    value: matchedLocation?.locationName || '', // Fallback to empty string
                };
    
                // Add shiftOption, jobPosition, and location to updatedAttendanceFormData
                // updatedAttendanceFormData.shiftOption = shiftOption;
                // updatedAttendanceFormData.jobPosition = jobPosition;
                // updatedAttendanceFormData.location = location;

                updatedAttendanceFormData[shiftOption?.value] = {
                    location: location,
                    jobPosition: jobPosition
                }
    
                // Set attendance form data with the updated information
                setAttendanceFormData(updatedAttendanceFormData);
                setShiftOptionList(shiftOption)
                
    
                updatedAttendanceDataState.id = matchedAssignShift?.uId || '';
                updatedAttendanceDataState.name = matchedShift.shiftName || ''
                updatedAttendanceDataState.punchOutTime = '';

                    // Validate and process punchInTime
                const punchInTime = latestAttendanceData?.punchIn;
                updatedAttendanceDataState.punchInTime = punchInTime && isValidTime(punchInTime) 
                    ? processTimeData(punchInTime) 
                    : '';

                // Validate and process breakTime
                const breakData = latestAttendanceData.breaks?.[0]?.break;
                updatedAttendanceDataState.breakTime = breakData && isValidTime(breakData) 
                    ? processTimeData(breakData) 
                    : '';

                // Validate and process returnBreakTime
                const returnBreakData = latestAttendanceData.breaks?.[0]?.returnFromBreak;
                updatedAttendanceDataState.returnBreakTime = returnBreakData && isValidTime(returnBreakData) 
                    ? processTimeData(returnBreakData) 
                    : '';
                
                console.log('update to be completed attendance data', updatedAttendanceDataState)
    
                // Set attendanceDataState with the initialized values
                setAttendanceDataStates(updatedAttendanceDataState);
            }
            else {
                dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
            }
        }
    }, [toBeCompletedShiftId, shiftDataApi, allAssignShiftData, locationDataApi, latestAttendanceData]);


    useEffect(() => {
        handleSettingWidgetState(isLatestToPunchOut, isEmptyShiftData, attendanceDataStates, allowedTime, dispatch, setWidgetState, setIsTimerRunning)        
    }, [attendanceDataStates, allowedTime, isLatestToPunchOut, isEmptyShiftData]);

    // useEffect(() => {
    //     console.log('useEffect triggered with fetchTrigger:', fetchTrigger);
    
    //     // Check if fetchTrigger is true
    //     if (fetchTrigger) {
    
    //         // Reset attendance form and data
    //         setAttendanceFormData({
    //             shiftOption: '',
    //             jobPosition: '',
    //             location: '',
    //         });
    //         setAttendanceDataStates({
    //             id: '',
    //             name: '',
    //             punchInTime: '',
    //             punchOutTime: '',
    //             breakTime: '',
    //             returnBreakTime: '',
    //         });
    
    //         // Check if shiftTodayData is not empty and has shifts
    //         if (shiftTodayData && shiftTodayData?.shifts && shiftTodayData?.shifts?.length > 0) {
            
    //             const updatedShiftTodayData = {
    //                 ...shiftTodayData, // Create a shallow copy of the whole object
    //                 shifts: shiftTodayData.shifts.slice(1), // Update only the shifts array to exclude the first element
    //             };
                
    //             // Set the state with the updated object
    //             dispatch(setShiftTodayData(updatedShiftTodayData));
                
    //             // Check if newShifts still has length
    //             if (updatedShiftTodayData?.shifts?.length > 0) {
    //                 // Set the uId of the new first shift in the array
    //                 const updatedShift = updatedShiftTodayData.shifts?.[0]?.shift;
    //                 if (updatedShift) {
    //                     setCurrentShiftSchedulingId(updatedShift.uId);
    //                 }
    //             } 
    //             // If there are no shifts left
    //             else {
    //                 dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
    //                 dispatch(setIsEmptyShiftData(true));
    //             }
    //         }
            
    //         setIsLatestToPunchOut(false);
    //         // Reset fetchTrigger
    //         setFetchTrigger(false);
    //     }
    // }, [fetchTrigger]);

    // dispatch(setIsEmptyShiftData(true));

    useEffect(() => {
        console.log('useEffect triggered with fetchTrigger:', fetchTrigger);
    
        // Check if fetchTrigger is true
        if (fetchTrigger) {
    
            // Reset attendance form and data
            setAttendanceFormData({});
            setAttendanceDataStates({
                id: '',
                name: '',
                punchInTime: '',
                punchOutTime: '',
                breakTime: '',
                returnBreakTime: '',
            });
            handleDispatchAllowedTime({})
    
            // Check if shiftTodayData is not empty and has shifts
            if (shiftTodayData && shiftTodayData?.shifts && shiftTodayData?.shifts?.length > 0) {
            
                // const updatedShiftTodayData = {
                //     ...shiftTodayData, // Create a shallow copy of the whole object
                //     shifts: shiftTodayData.shifts.slice(1), // Update only the shifts array to exclude the first element
                // };

                const updatedShiftTodayData = shiftTodayData.shifts.filter((shift) => shift.Id !== currentShiftSchedulingId)
                
                // Set the state with the updated object
                dispatch(setShiftTodayData(updatedShiftTodayData));
                
                // Check if newShifts still has length
                if (updatedShiftTodayData?.shifts?.length > 0) {
                    // Set the uId of the new first shift in the array
                    const updatedShift = updatedShiftTodayData.shifts?.[0]?.shift;
                    if (updatedShift) {
                        setCurrentShiftSchedulingId(updatedShift.uId);
                    }
                } 
                // If there are no shifts left
                else {
                    dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
                    dispatch(setIsEmptyShiftData(true));
                }
            }
            
            setIsLatestToPunchOut(false);
            // Reset fetchTrigger
            setFetchTrigger(false);
        }
    }, [fetchTrigger]);


    // Save attendanceDataStates to localStorage whenever it changes
    useEffect(() => {
        saveToLocalStorage('attendanceDataStates', attendanceDataStates);
    }, [attendanceDataStates]);

    // Save attendanceFormData to localStorage whenever it changes
    useEffect(() => {
        saveToLocalStorage('attendanceFormData', attendanceFormData);
    }, [attendanceFormData]);


    const handleSettingWidgetState = (
        isLatestToPunchOut,
        isEmptyShiftData,
        attendanceDataStates,
        allowedTime,
        dispatch,
        setWidgetState,
        setIsTimerRunning,
      ) => {
        const currentTime = new Date();
        
        const setTimeOnCurrentDate = (timeString) => {
            if (!timeString) return;
            const [hours, minutes] = timeString.split(':');
            const updatedDate = new Date(currentTime);
            updatedDate.setHours(hours);
            updatedDate.setMinutes(minutes);
            updatedDate.setSeconds(0); // Set seconds to 0 for consistency
            return updatedDate;
          };
      
        const allowedStartTime = setTimeOnCurrentDate(allowedTime?.allowedStartTime);
        const allowedEndTime = setTimeOnCurrentDate(allowedTime?.allowedEndTime);
        const allowedStartBreakTime = setTimeOnCurrentDate(allowedTime?.allowedStartBreakTime);
        const allowedEndBreakTime = setTimeOnCurrentDate(allowedTime?.allowedEndBreakTime);
        
        // console.log('allowed end break time in widget', allowedEndBreakTime);
        // no more shift data to fill
        if (isEmptyShiftData) {
          dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
          setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
          return;
        }
      
        // Case 1: latestToPunchOut is true
        if (isLatestToPunchOut) {
          dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showDisableBreakWidget'], value: true }));
        //   setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
          return;
        }
      
        // Case 2: latestToPunchOut is false
        if (!isLatestToPunchOut) {
            // Case 2.1: attendanceDataStates is empty or punchInTime is empty
          if (!attendanceDataStates || !attendanceDataStates.punchInTime) {
            if (currentTime >= allowedStartTime && currentTime <= allowedEndTime) {
              // Case 2.1.1: currentTime >= allowedStartTime
              dispatch(setWidgetState({ widgetKeys: ['showPunchInWidget', 'showDisableBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
            } else {
              // Case 2.1.2: show disabled punch-in and break widgets
              dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
            }
          }
      
          // Case 2.2: punchInTime filled, punchOutTime and breakTime are empty
          if (attendanceDataStates.punchInTime && !attendanceDataStates.punchOutTime && !attendanceDataStates.breakTime) {
            // Case 2.2.1: If current time is within allowedStartBreakTime and allowedEndBreakTime, show breakWidget
            if (currentTime >= allowedStartBreakTime && currentTime <= allowedEndBreakTime) {
              dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
            }
            // Case 2.2.2: If current time is before allowedStartBreakTime and before or at allowedEndTime, disable both widgets
            else if (currentTime <= allowedStartBreakTime && currentTime <= allowedEndTime) {
              dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
            }
            // Case 2.2.3: If current time is after allowedStartBreakTime and after allowedEndTime, show punchOutWidget
            else if (currentTime >= allowedEndBreakTime) {
              dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
            }
          }
      
          // Case 2.3: punchIn, breakTime are filled, returnBreakTime and punchOutTime are empty
          if (attendanceDataStates.punchInTime && attendanceDataStates.breakTime && !attendanceDataStates.returnBreakTime && !attendanceDataStates.punchOutTime) {
            // Case 2.3.1: If current time is >= allowedEndBreakTime but < allowedEndTime, show returnBreakWidget
            if (currentTime >= allowedEndBreakTime && currentTime <= allowedEndTime) {
              dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showReturnBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: true }));
            }
            // Case 2.3.2: If current time is >= allowedEndBreakTime and >= allowedEndTime, show punchOutWidget
            else if (currentTime >= allowedEndBreakTime && currentTime >= allowedEndTime) {
              dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showReturnBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: true }));
            }
             // Case 2.3.3: If current time is below allowedEndBreakTime
             else {
                dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
                setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: true }));
              }
          }
      
          // Case 2.4: punchIn, breakTime, returnBreakTime filled, punchOutTime is not filled
          if (attendanceDataStates.punchInTime && attendanceDataStates.breakTime && attendanceDataStates.returnBreakTime && !attendanceDataStates.punchOutTime) {
            if (currentTime >= allowedEndTime) {
              // Case 2.4.1: currentTime >= endTime
              dispatch(setWidgetState({ widgetKeys: ['showPunchOutWidget', 'showDisableBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
            } else {
              // Case 2.4.2: currentTime <= endTime
              dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
              setIsTimerRunning(prev => ({ ...prev, punchWidget: true, breakWidget: false }));
            }
          }
      
          // Case 2.5: All data in attendanceDataStates are filled
          if (attendanceDataStates.punchInTime && attendanceDataStates.breakTime && attendanceDataStates.returnBreakTime && attendanceDataStates.punchOutTime) {
            dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
            setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
          }
      
          // Case 2.6: punchInTime and punchOutTime exist, but no breakTime or returnBreakTime
          if (attendanceDataStates.punchInTime && attendanceDataStates.punchOutTime && !attendanceDataStates.breakTime && !attendanceDataStates.returnBreakTime) {
            dispatch(setWidgetState({ widgetKeys: ['showDisablePunchWidget', 'showDisableBreakWidget'], value: true }));
            setIsTimerRunning(prev => ({ ...prev, punchWidget: false, breakWidget: false }));
          }
        }
      };
    
    console.log('all shift data', shiftDataApi);
    console.log('attendance data', attendanceFormData);
    // console.log('attendance time data', attendanceDataStates);
    console.log('allowed time data empty', allowedTime);
    console.log('is latest', isLatestToPunchOut);
    console.log('is empty', isEmptyShiftData);
    console.log('is initial', isInitialSetShiftData);
    // console.log('current shiftscheduling id', currentShiftSchedulingId);
    console.log('to be completed shiftscheduling id', toBeCompletedShiftId);
    // console.log('current shift today data', shiftTodayData);
    console.log('shift option data', shiftOptionList);
    console.log('latest attendance data', latestAttendanceData);
    
    

    // Debounced update timer state to localStorage
    useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.setItem('timers', JSON.stringify(timers));
        }, 500); // Update localStorage every 500ms

        return () => clearTimeout(timer);
    }, [timers]);

    // Persist timer running bool state to localStorage
    useEffect(() => {
        localStorage.setItem('isTimerRunning', JSON.stringify(isTimerRunning));
    }, [isTimerRunning]);



    // Timer running effect
    useEffect(() => {

        const timerIntervals = {};

        if (isTimerRunning?.punchWidget) {
            timerIntervals.punchWidget = setInterval(() => {
                setTimers(prevTimers => ({ ...prevTimers, punchWidget: prevTimers.punchWidget + 1 }));
            }, 1000);
        } 
        else {
            setTimers(prevTimers => ({ ...prevTimers, punchWidget: 0 }));
        }

        if (isTimerRunning?.breakWidget) {
            timerIntervals.breakWidget = setInterval(() => {
                setTimers(prevTimers => ({ ...prevTimers, breakWidget: prevTimers.breakWidget + 1 }));
            }, 1000);
        } else {
            setTimers(prevTimers => ({ ...prevTimers, breakWidget: 0 }));
        }

        return () => {
            clearInterval(timerIntervals.punchWidget);
            clearInterval(timerIntervals.breakWidget);
        };
    }, [isTimerRunning]);


    const handleOpenModal = (modalKey) => {
        console.log(modalKey);
        setModalShow({
            punchModal: false,
            breakModal: false,
            punchOutModal: false,
            returnBreakModal: false,
            [modalKey]: true,
        });
    };

    const handleCloseModal = () => {
        setModalShow({
            punchModal: false,
            breakModal: false,
            punchOutModal: false,
            returnBreakModal: false,
        });
    };

    const handlePunchInFormSubmit = (currentShiftId) => {
        // Setting fetch trigger state to true to trigger useEffect for fetching new attendance record
        const matchedShift = shiftDataApi?.find((shift) => shift.uId === currentShiftId);
        setAttendanceDataStates(prev => ({
            ...prev,
            name: matchedShift?.name || '',
            id: matchedShift?.uId || ''
        }));

        setCurrentShiftSchedulingId(currentShiftId)
        getAndSetAllowedTime(matchedShift, handleDispatchAllowedTime, attendanceUserSetting);
    };

    const handlePunchOutFormSubmit = () => {
        // Setting fetch trigger state to true to trigger useEffect for fetching new attendance record
        setFetchTrigger(true);
        console.log('fetch trigger set run');
    };

    
    const handleAttendanceDataUpdate = (dataName, data) => {
        setAttendanceDataStates(prev => ({
            ...prev,
            [dataName]: processTimeData(data)  // Use [] to set dynamic key
        }));
    };

    const month = formatMonthYear(currentMonth) || "this month";


    return (
        <>
            <div className="flex flex-col mt-4">
                <p className="text-lg font-bold">Welcome Back, {currentUserName}</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 sm:gap-3 mt-4">
                {/* Left side */}
                <div className="col-span-3 flex flex-col gap-2">
                    {/* Widget Card Section */}
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <DataWidget attendanceDataStates={attendanceDataStates} currentShiftSchedulingId={currentShiftSchedulingId} />

                        {widgetStates.showPunchInWidget && (
                            <PunchInWidget handleOpenModal={handleOpenModal} modalKey="punchModal" timer={timers.punchWidget} punchInTime={attendanceDataStates.punchInTime} />
                        )}
                        {widgetStates.showPunchOutWidget && (
                            <PunchOutWidget handleOpenModal={handleOpenModal} modalKey="punchOutModal" timer={timers.punchWidget} punchInTime={attendanceDataStates.punchInTime} />
                        )}
                        {widgetStates.showDisablePunchWidget && (
                            <PunchDisabledWidget punchInTime={attendanceDataStates.punchInTime} timer={timers.punchWidget} />
                        )}
                        {widgetStates.showBreakWidget && (
                            <BreakWidget handleOpenModal={handleOpenModal} modalKey="breakModal" timer={timers.breakWidget} breakTime={attendanceDataStates.breakTime} />
                        )}
                        {widgetStates.showReturnBreakWidget && (
                            <ReturnBreakWidget handleOpenModal={handleOpenModal} modalKey="returnBreakModal" timer={timers.breakWidget} breakTime={attendanceDataStates.breakTime} />
                        )}
                        {widgetStates.showDisableBreakWidget && (
                            <BreakDisabledWidget breakTime={attendanceDataStates.breakTime} timer={timers.breakWidget} />
                        )}
                    </div>

                    {/* Form Modals Section */}
                    {modalShow.punchModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='Punch In' onClose={handleCloseModal} className={'w-[700px]'}>
                                <PunchInForm onClose={handleCloseModal} handleDataSubmit={handleAttendanceDataUpdate} shiftOptionList = {shiftOptionList} attendanceFormData={attendanceFormData} handlePunchInFormSubmit={handlePunchInFormSubmit}/>
                            </TestModalDialog>
                        </div>
                    )}

                    {modalShow.breakModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='Break' onClose={handleCloseModal} className={'w-[700px]'}>
                                <BreakForm onClose={handleCloseModal} handleDataSubmit={handleAttendanceDataUpdate} attendanceFormData={attendanceFormData} shiftOptionList={shiftOptionList} currentShiftSchedulingId={currentShiftSchedulingId} />
                            </TestModalDialog>
                        </div>
                    )}
                    {modalShow.punchOutModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='PunchOut' onClose={handleCloseModal} className={'w-[700px]'}>
                                <PunchOutForm onClose={handleCloseModal} handleDataSubmit={handleAttendanceDataUpdate} handlePunchOutSubmit = {handlePunchOutFormSubmit} attendanceFormData={attendanceFormData} shiftOptionList={shiftOptionList} currentShiftSchedulingId={currentShiftSchedulingId} toBeCompletedShiftId={toBeCompletedShiftId}/>
                            </TestModalDialog>
                        </div>
                    )}
                    {modalShow.returnBreakModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='Return Break' onClose={handleCloseModal} className={'w-[700px]'}>
                                <ReturnBreakForm onClose={handleCloseModal} handleDataSubmit={handleAttendanceDataUpdate} attendanceFormData={attendanceFormData} shiftOptionList={shiftOptionList} currentShiftSchedulingId={currentShiftSchedulingId} />
                            </TestModalDialog>
                        </div>
                    )}
                </div>

                {/* Right side */}
                <div className="w-full sm:col-span-1 flex mt-3 sm:mt-0 flex-col">
                    <CustomCalendar setDate={setDateData} />
                    <div className="text-container flex justify-between items-center">
                        <p className="text-label">What's on in {month} ?</p>
                        <button className="bg-white text-black w-10 h-10 flex-shrink-0 m-1 border-none text-2xl">+</button>
                    </div>
                    <Tabs
                        options={exampleOptions}
                        width={95}
                        day={day}
                    />
                </div>
            </div>

        </>
    );
}

export default EmployeeDashboardPage;