import React from 'react';
import Chart from 'react-apexcharts';

const ProgressBar = ({ stages }) => {
    console.log('stages received', stages);
    return (
        <div className="flex bg-gray-200 h-3 w-full rounded-full overflow-hidden">
            {stages.map((stage, index) => (
                <div
                    key={index}
                    className="h-full"
                    style={{
                        width: `${stage.progress}%`,
                        backgroundColor: stage.color,
                        borderRadius: index === 0 ? '3px 0 0 3px' : index === stages.length - 1 ? '0 3px 3px 0' : 'none'
                    }}
                />
            ))}
        </div>
    );
};

const DonutChart = ({ data }) => {
    const options = {
        chart: {
            type: 'donut',
            height: 150,
            width: 140,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: 50,
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            color: undefined,
                            offsetY: -8,
                            formatter: function (val) {
                                return val
                            }
                        },
                        value: {
                            show: true,
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            color: undefined,
                            offsetY: 0,
                            formatter: function (val) {
                                return val
                            }
                        },
                        total: {
                            show: true,
                            showAlways: false,
                            label: 'Total',
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            color: '#373d3f',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                            }
                        }
                    }
                }
            }

        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + '%';
            },
            style: {
                fontSize: '10px',
            },
        },
    };

    const series = data.map((item) => item.value);
    const colors = data.map((item) => item.color); // Extract colors from data array

    return <Chart options={{ ...options, colors }} series={series} type="donut" />;
};

const ColorLabel = ({ attendanceSummary }) => {
    const circleStyle = {
        width: '0.4rem',
        height: '0.4rem',
        borderRadius: '50%',
        marginLeft: '0.6rem'
    };

    // Function to safely get rounded values or return default
    const getRoundedValue = (value) => {
        return value ? Math.round(value) : 0; // Default to 0 if value is undefined or null
    };

    return (
        <div className="flex p-1 justify-between" style={{ width: '100%' }}>
            <div className="flex justify-center gap-1">
                <div className='flex items-center'>
                    <div style={{ ...circleStyle, backgroundColor: '#4BD394' }}></div>
                </div>
                <div className="flex flex-col">
                    <p className="color-label text-xs">{getRoundedValue(attendanceSummary?.percentages?.onTimePercentage)}%</p>
                    <p className="colordesc-label text-xs">On Time</p>
                </div>
            </div>
            <div className="flex justify-center gap-1">
                <div className='flex items-center'>
                    <div style={{ ...circleStyle, backgroundColor: '#B83C3C' }}></div>
                </div>
                <div className="flex flex-col">
                    <p className="color-label text-xs">{getRoundedValue(attendanceSummary?.percentages?.latePercentage)}%</p>
                    <p className="colordesc-label text-xs">Late</p>
                </div>
            </div>
            <div className="flex justify-center gap-1">
                <div className='flex items-center'>
                    <div style={{ ...circleStyle, backgroundColor: '#EFCA4F' }}></div>
                </div>
                <div className="flex flex-col">
                    <p className="color-label text-xs">{getRoundedValue(attendanceSummary?.percentages?.noPunchInOutPercentage)}%</p>
                    <p className="colordesc-label text-xs">Absence</p>
                </div>
            </div>
            <div className="flex justify-center gap-1" >
                <div className='flex items-center'>
                    <div style={{ ...circleStyle, backgroundColor: '#A3A3AB' }}></div>
                </div>
                <div className="flex flex-col">
                    <p className="color-label text-xs">{getRoundedValue(attendanceSummary?.percentages?.leavePercentage)}%</p>
                    <p className="colordesc-label text-xs">On Leave</p>
                </div>
            </div>
        </div>
    );
};


export { ProgressBar, DonutChart, ColorLabel };