import PunchOutIcon from 'assets/icon/PunchOutSVG';
import DisablePunchIcon from 'assets/icon/DisablePunchSVG';
import { formatTime } from "utils/utils";

const PunchDisabledWidget = ({ punchInTime, timer }) => {
    const formattedPunchInTime = punchInTime ? punchInTime : "No Punch In Time";
    const formattedTimer = timer ? formatTime(timer) : "-- : -- : --";
    return (
        <div className='rounded-lg shadow-xl flex items-center justify-between relative border-gray-300 border-2 p-2 gap-2 cursor-not-allowed'>
            <div className="left-container flex flex-col gap-2">
                <p className="text-xs font-bold">Punch-in to shift</p>
                <p className="text-xs">{formattedPunchInTime}</p>
                <p className="text-lg font-bold">{formattedTimer}</p>
                <p className="text-xs">Morning Shift</p>
            </div>
            <div className="right-container mr-8">
                <DisablePunchIcon />
            </div>
            <div className="absolute inset-0 bg-gray-100 opacity-50 rounded-lg"></div>
        </div>

    )
}

export default PunchDisabledWidget;