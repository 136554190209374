// import React, { useState } from "react";
// import { Calendar } from "react-date-range";
// import PropTypes from "prop-types";
// import { enGB } from 'date-fns/locale';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './inputdate.css'
import { format, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';

// const TestInputDate = ({
//   required,
//   label,
//   name,
//   disabled,
//   value,
//   errors,
//   setFieldValue,
//   onChange, // Accept onChange as a prop
//   ...props
// }) => {
//   const [showCalendar, setShowCalendar] = useState(false);

//   const formatDate = (date) => {
//     console.log('date', date);
//     // Check if date is already a Date object
//     if (!(date instanceof Date)) {
//         // Check if date is a valid date string in dd/mm/yyyy format
//         if (typeof date === "string") {
//             const parts = date.split("/");
//             // Validate parts length and create a date in yyyy-mm-dd format
//             if (parts.length === 3) {
//                 // Create new Date from parts (remember month is 0-indexed)
//                 date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
//             } else {
//                 // Return a default value or throw an error if format is incorrect
//                 return "Invalid date format";
//             }
//         } else {
//             date = new Date(date); // Fallback to standard Date creation
//         }
//     }

//     // Check if the date is valid
//     if (isNaN(date.getTime())) {
//         return "Invalid date"; // Return an error message for invalid date
//     }
    
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = String(date.getFullYear()).slice(-2);
//     return `${day}/${month}/${year}`;
// };

//   const displayYear = (year) => {
//     return year.slice(-2);
//   };

//   const handleChangeCalendar = (date) => {
//     // Set the field value using Formik's setFieldValue
//     const dateUpdate= formatDate(date)
//     setFieldValue(name, formatDate(dateUpdate));
//     setShowCalendar(false);
    
//     // Call the external onChange function passed via props
//     if (onChange) {
//       onChange(dateUpdate);
//     }
//   };

//   const handleClick = () => {
//     setShowCalendar(!showCalendar);
//   };


//   return (
//     <div className={`w-full relative ${disabled && "bg-[#F2F4F5]"} select-none`}>
//       <div className="w-full relative h-[50px]">
//         <div
//           className={`w-full h-[60px] ${!disabled && "border-[1px]"} border-black p-3 relative rounded-md ${disabled && "bg-[#F2F4F5]"}`}
//         >
//           <div className="absolute top-[6px] max-w-[350px] text-[14px] truncate text-black">
//             {label} <span className="text-black">{required ? "*" : ""}</span>{" "}
//           </div>
//           <input
//             type="text"
//             id={name}
//             // value={
//             //   value === ""
//             //     ? "dd/mm/yy"
//             //     : `${value.slice(0, 5)}/${displayYear(value.slice(6, 10))}`
//             // }
//             // value={value ? value : "dd/mm/yy"}
//             value={
//                 value === ""
//                   ? "dd/mm/yy"
//                   : formatDate(new Date(value)) // Format the date value
//               } 
//             onClick={handleClick}
//             readOnly
//             className={` w-full h-full outline-none rounded-md cursor-pointer absolute top-0 left-0 pt-5 pl-3 text-[14px] bg-transparent z-40 ${disabled && 'bg-[#F2F4F5]'} select-none`}
//             {...props}
//           />
//         </div>

//         <div className="absolute right-[15px] top-[35%] cursor-pointer ">
//           <CalendarSvg />
//         </div>
//       </div>

//       {errors && !showCalendar && (
//         <div className={` text-red-500 pt-[7px]`}>{errors}</div>
//       )}

//       <div className="absolute top-[45px] z-50">
//         {showCalendar && !disabled && (
//           <Calendar
//             date={new Date()}
//             onChange={(date) => handleChangeCalendar(date)} // Call handleChangeCalendar
//             className="shadow-md rounded-md"
//             locale={enGB}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// TestInputDate.propTypes = {
//   required: PropTypes.bool,
//   label: PropTypes.string,
//   name: PropTypes.string,
//   value: PropTypes.string,
//   errors: PropTypes.any,
//   setFieldValue: PropTypes.func,
//   onChange: PropTypes.func, // Add onChange propType
//   disabled: PropTypes.bool,
// };

// TestInputDate.defaultProps = {
//   required: false,
//   label: "",
//   name: "",
//   value: "",
//   errors: null,
//   setFieldValue: () => {},
//   onChange: () => {}, // Default empty onChange
//   disabled: false,
// };

// export default TestInputDate;

// function CalendarSvg() {
//   return (
//     <svg
//       width="20"
//       height="22"
//       viewBox="0 0 20 22"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M1.09277 8.40445H18.9167"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M14.442 12.3088H14.4512"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M10.0045 12.3088H10.0137"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M5.55769 12.3088H5.56695"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M14.442 16.1955H14.4512"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M10.0045 16.1955H10.0137"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M5.55769 16.1955H5.56695"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M14.0438 1V4.29078"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M5.96564 1V4.29078"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
//         stroke="#1A1A2E"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// }



const TestInputDate = ({
    required,
    label,
    name,
    disabled,
    value,
    errors,
    setFieldValue,
    onChange,
    ...props
  }) => {
    const [startDate, setStartDate] = useState(value ? new Date(value) : null);
  
    const formatDate = (date) => {
      return format(date, 'dd/MM/yy', { locale: enGB });
    };
  
    const handleChange = (date) => {
      setStartDate(date);
      const formattedDate = date ? formatDate(date) : '';
      setFieldValue(name, formattedDate);
      // Call the external onChange function passed via props
      if (onChange) {
        onChange(formattedDate);
      }
    };
  
    return (
    <>
      <div className={`flex relative w-full ${disabled ? "bg-[#F2F4F5]" : ""} select-none`}>
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          dateFormat="dd/MM/yy"
          placeholderText=" " // Add a space for the placeholder effect
          disabled={disabled}
          name={name} 
          className={`w-full h-[60px] p-3 border ${disabled ? 'bg-[#F2F4F5]' : 'border-black'} rounded-md text-sm`}
          {...props}
        />
        <div className="flex items-center">
            <label
              className={`absolute left-3 top-3 transition-all text-sm duration-200 ease-in-out ${
                startDate || value ? "transform -translate-y-2 scale-75 text-gray-500" : ""
              }`}
            >
              {label} {required ? "*" : ""}
            </label>
            <div className="absolute right-[15px] top-[35%] cursor-pointer ">
                 <CalendarSvg />
            </div>
        </div>
        
      </div>
      {errors &&  <div className={` text-red-500 pt-[7px]`}>{errors}</div>}
    </>
    );
  };

  function CalendarSvg() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09277 8.40445H18.9167"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.442 12.3088H14.4512"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0045 12.3088H10.0137"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.55769 12.3088H5.56695"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.442 16.1955H14.4512"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0045 16.1955H10.0137"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.55769 16.1955H5.56695"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0438 1V4.29078"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.96564 1V4.29078"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
  
  export default TestInputDate;

// export default TestInputDate;