import React from "react";
import { ConflictIcon, ReplacementSvg } from '../Icon.js'

function ConflickComponent({
  data,
  handleConflickAssign,
  assignShift,
  handleEditAssignShift,
  allShift,
  employeeData,
}) {
  const combineShiftAndFilterData = {
    allShift: allShift,
    assignShift: assignShift,
    employee: employeeData,
  };

  return (
    <div className="mt-4">
      {data.map((item, index) => {
        return (
          <div key={index} className="relative cursor-pointer mt-2 mb-2">
            <div
              style={{
                backgroundColor: item.shiftDetails.color,
              }}
              onClick={() =>
                handleEditAssignShift(
                  combineShiftAndFilterData,
                  assignShift.uId
                )
              }
              className={`flex flex-col justify-center items-center h-[56px] w-full cursor-pointer rounded-md border-[1px]  ${!item.isActive && "opacity-60"
                }`}
            >
              <h1>{item.jobPosition}</h1>
              <p>
                {item.shiftDetails.startTime}- {item.shiftDetails.endTime}
              </p>
            </div>

            {assignShift.isReplacement ? (
              <div
                className="absolute top-[-5px] right-[-5px]"
              // onClick={() => handleConflickAssign(assignShift)}
              >
                <ReplacementSvg />
              </div>
            ) : (
              <div
                className="absolute top-[-5px] right-[-5px]"
                onClick={() => handleConflickAssign(assignShift)}
              >
                <ConflictIcon />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ConflickComponent;
