import React, { forwardRef, useEffect, useState } from "react";

import ConflickComponent from "./ConflickComponent";
import HoverComponent from "./HoverComponent";
import AssignShiftData from "./AssignShiftData";

const EmployeeMap = forwardRef(
  (
    {
      dataDate,
      handleRef,
      widthScroll,
      dataAssignShift,
      dataEmployees,
      filteredData,
      locationState,
      handleShowAssign,
      handleEditAssignShift,
      handleConflickAssign,
      roleUser,
    },
    ref
  ) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const allDataApi =
        dataEmployees.length > 0 &&
        dataEmployees.map((itemEmp) => {
          let filteredEmployee;

          // CONDITION FOR SELECT LOCATION
          if (locationState !== "") {
            filteredEmployee = itemEmp.employee.filter((itemEmp) => {
              return itemEmp.locationID === locationState;
            });
          } else {
            filteredEmployee = itemEmp.employee;
          }

          return {
            ...itemEmp,
            employee: filteredEmployee,
            shiftData: filteredData,
          };
        });

      setData(allDataApi);
    }, [dataDate, filteredData, dataAssignShift, dataEmployees, locationState]);

    // Fungsi untuk mencari total length terbesar
    function findMaxLength(data) {
      let maxLength = 0;

      data.forEach((item) => {
        const assignLength = item.assignAndConflick.length;
        let cumulativeConflickLength = 0;

        // Iterasi setiap assignAndConflick
        item.assignAndConflick.forEach((conflick) => {
          // Tambahkan length conflickData kumulatif
          cumulativeConflickLength += conflick.conflickData.length;

          // Hitung total length dengan menambahkan cumulativeConflickLength
          const totalLength = assignLength + cumulativeConflickLength;

          // Update maxLength jika ditemukan total length lebih besar
          if (totalLength > maxLength) {
            maxLength = totalLength;
          }
        });
      });

      return maxLength;
    }

    // MAPPING STRUCTURE FOR MAPPING
    const forStructure =
      data?.length > 0 &&
      data?.map((all) => {
        const jobPosition = all.jobPosition;

        // SHIFT DATA BASE ON DATA EMPLOYEE
        const filteredEmployee = all?.employee?.map((itemEmp) => {
          let isAnyConflick = false;
          // let lengthEveryAssign = 0;

          const shiftDatas = all?.shiftData?.map((shift) => {
            // filter to hover or not base on jobPosition
            const filterBaseOnJobPosition =
              shift?.shiftDetails?.length > 0 &&
              shift?.shiftDetails?.map((item) => {
                const baseOn = item.employees.some(
                  (job) => job.jobPosition === jobPosition
                );

                return baseOn;
              });

            // FILTER DATA
            const filterDatasBaseOnJobPosition =
              shift.shiftDetails?.length > 0 &&
              shift.shiftDetails.filter((item) => {
                const baseOn = item.employees.some(
                  (job) => job.jobPosition === jobPosition
                );

                return baseOn;
              });

            // Data TO ASSIGN
            const toAssignShift =
              filterDatasBaseOnJobPosition?.length > 0 &&
              filterDatasBaseOnJobPosition?.map((item) => {
                return {
                  ...item,
                  date: shift.date,
                  jobPosition: jobPosition,
                };
              });

            // FILTER TO SHOW ASSIGN
            const filterToAssign =
              dataAssignShift &&
              dataAssignShift?.length > 0 &&
              dataAssignShift?.filter((assign) => {
                // const assignDetails = assign.shiftDetails;
                const newDate = new Date(assign.date);
                const shiftDate = new Date(shift.date);

                return (
                  newDate.getTime() === shiftDate.getTime() &&
                  assign.userId === itemEmp.uId
                );
              });

            // filter jam yang sama berdasarkan startTime and Endtime dan masukkan kedalam property conflickData

            const findConflickData = (() => {
              const seen = new Map();
              const result = [];

              filterToAssign?.length > 0 &&
                filterToAssign.forEach((item) => {
                  const key = `${item.shiftDetails.startTime}-${item.shiftDetails.endTime}`;

                  if (seen.has(key)) {
                    // if there is conflick data create property conflickData and push
                    const existingItem = seen.get(key);
                    existingItem.conflickData.push(item);
                  } else {
                    // if there is no conflick data just push
                    item.conflickData = [];
                    seen.set(key, item);
                    result.push(item);
                  }
                });

              return result;
            })();

            // FIND CONFLICK DATA
            const hasConflick =
              findConflickData?.length > 0 &&
              findConflickData?.some((item) => {
                return item.conflickData?.length > 0;
              });

            if (hasConflick) {
              isAnyConflick = true;
            }

            return {
              ...shift,
              filterBaseOnJobPosition,
              toAssignShift,
              assignAndConflick: findConflickData,
            };
          });

          // const ceckStatusHeight = shiftDatas.
          const findLenght = findMaxLength(shiftDatas);

          // itemEmp.isAnyConflick = isAnyConflick;
          // console.log("SHIFT DATA : ", findLenght);

          return {
            ...itemEmp,
            shiftData: shiftDatas,
            isAnyConflick,
            // lengthEveryAssign: shiftDatas.assignAndConflick.length + 1,
            lengthEveryAssign: findLenght,
          };
        });

        return {
          jobPosition: all.jobPosition,
          jobPositionID: all.jobPositionID,
          organizationID: all.organizationId,
          employeeAssignShift: filteredEmployee,
        };
      });

    return (
      <div className="flex flex-col w-full ">
       {forStructure?.length > 0 &&
          forStructure?.map((item, dataIndex) => {
            // Determine if this is the last item in forStructure
            const isLastItem = dataIndex === forStructure?.length - 1;
            return (
              <div
                key={dataIndex}
                className={`overflow-auto ${item.employeeAssignShift?.length
                  ? "border-gray-400 border-[1px]"
                  : "hidden"
                  }`}
              >
                {/* iF EMPLOYEE LENGTH > 0 show job position  */}
                <div className={`flex flex-col w-full`}>
                  <div className="flex items-center gap-3 w-[402px] h-[40px] px-3 ">
                    <ChevronBottom
                      color={"black"}
                      className={"font-bold w-[20px] h-[20px]"}
                    />
                    <h1>
                      {item.jobPosition} ({item.employeeAssignShift?.length}{" "}
                      Employees)
                    </h1>
                  </div>
                </div>

                <div>
                  {item.employeeAssignShift?.length > 0 &&
                    item.employeeAssignShift.map((itemEmp, empIdx) => {
                      return (
                        <div className="flex w-full" key={empIdx}>
                          {/* <h1>employe idx : {empIdx}</h1> */}
                          <div
                            className={`border-y border-r border-gray-400 min-w-[402px]  flex items-center pl-3`}
                          >
                            <div className="flex gap-4 items-center">
                              <img
                                src={itemEmp.photo}
                                alt="img"
                                className="w-[40px] h-[40px] rounded-full"
                              />
                              <div className="flex flex-col h-full justify-between">
                                <h1 className="font-semibold">
                                  {itemEmp.firstName + " " + itemEmp.lastName}
                                </h1>
                                <div className="flex gap-2 items-center">
                                  <TimeCircle />
                                  <div>{itemEmp.time} Hours / Week</div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              minHeight: itemEmp.lengthEveryAssign * 66,
                            }}
                            className={`flex w-full ${isLastItem && item.employeeAssignShift.length === empIdx + 1 ? 'overflow-x-auto' : 'overflow-hidden'} min-h-[${itemEmp.lengthEveryAssign * 64}px]`}
                            ref={(el) => {
                              // GET THE INDEX ARRAY IN ARRAY MAPPING USING REF2
                              if (!ref.current[dataIndex]) {
                                ref.current[dataIndex] = [];
                              }
                              ref.current[dataIndex][empIdx] = el;

                          
                            }}
                            onScroll={handleRef}
                          >
                            {itemEmp.shiftData?.length > 0 &&
                              itemEmp.shiftData?.map((shift, index) => {
                                const shiftData = shift.assignAndConflick;
                                // console.log("shift : ", shift);

                                return (
                                  <div
                                    key={index}
                                    className={`min-w-[210px] h-[64px] p-1 border-r border-y border-gray-400`}
                                  >
                                    {shift.assignAndConflick?.length > 0 ? (
                                      <div className=" flex flex-col justify-center items-center">
                                        {shiftData.map((assign, idxAssign) => {
                                          const conflickData =
                                            assign.conflickData;

                                          return (
                                            <div className="w-full">
                                              <div className="w-full h-[50px]">
                                                <AssignShiftData
                                                  assignShift={assign}
                                                  allShift={shift.toAssignShift}
                                                  employeeData={itemEmp}
                                                  key={idxAssign}
                                                  isAnyConflick={
                                                    itemEmp.isAnyConflick
                                                  }
                                                  handleEditAssignShift={
                                                    handleEditAssignShift
                                                  }
                                                  handleConflickAssign={
                                                    handleConflickAssign
                                                  }
                                                  roleUser={roleUser}
                                                />
                                              </div>
                                              {/* conflick data */}
                                              <ConflickComponent
                                                data={conflickData}
                                                assignShift={assign}
                                                handleConflickAssign={
                                                  handleConflickAssign
                                                }
                                                handleEditAssignShift={
                                                  handleEditAssignShift
                                                }
                                                allShift={shift.toAssignShift}
                                                employeeData={itemEmp}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <HoverComponent
                                        handleShowAssign={handleShowAssign}
                                        baseOnJobposition={
                                          shift.filterBaseOnJobPosition
                                        }
                                        shift={shift}
                                        itemEmp={itemEmp}
                                        roleUser={roleUser}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
);

export default EmployeeMap;

function TimeCircle() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8228 9.50039C16.8228 13.545 13.5445 16.8233 9.49992 16.8233C5.45529 16.8233 2.177 13.545 2.177 9.50039C2.177 5.45577 5.45529 2.17747 9.49992 2.17747C13.5445 2.17747 16.8228 5.45577 16.8228 9.50039Z"
        stroke="#A3A3AB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2165 11.8298L9.23193 10.0493V6.21213"
        stroke="#A3A3AB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ChevronBottom({ color, className, ...props }) {
  return (
    <svg
      className={`${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 9L12 15L18 9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
