import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChevronSVG from "assets/icons/chevron";
import { legacy_createStore } from '@reduxjs/toolkit';

const CustomCalendar = ({ currentDate, onClick, selectTextSize, contentTextSize, setDate }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [showPicker, setShowPicker] = useState(false);
    const [clickedDayKey, setClickedDayKey] = useState(null);

    useEffect(() => {
        setCurrentMonth(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    }, [currentDate]);

    const handlePrevMonth = () => {
        setCurrentMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(nextMonth => new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1));
    };

    const handleChangeMonth = (event) => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), parseInt(event.target.value), 1));
    };

    const handleChangeYear = (event) => {
        setCurrentMonth(new Date(parseInt(event.target.value), currentMonth.getMonth(), 1));
    };

    const handleTogglePicker = () => {
        setShowPicker(!showPicker);
    };

    const handleClick = (dayKey) => {
        const dayOfMonth = dayKey.split('-')[2];
        setDate(dayOfMonth);
        setClickedDayKey(dayKey);
    };

    const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0);
    const lastDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    const startingDay = firstDayOfMonth.getDay();

    const renderDays = () => {
        const days = [];

        // Menggeser hari-hari bulan sebelum tanggal 1
        for (let i = 0; i < startingDay; i++) {
            days.push(<div key={`empty-${i}`} className="empty-day" />);
        }

        let dayOfMonth = 1;
        // Mengelompokkan tanggal ke dalam minggu yang sesuai
        const weeks = [];
        while (dayOfMonth <= daysInMonth) {
            const week = [];
            for (let i = 0; i < 7; i++) {
                if (dayOfMonth <= daysInMonth) {
                    // const dayKey = `${currentMonth.getFullYear()}-${currentMonth.getMonth() + 1}-${dayOfMonth}`;
                    // // const classNames = `calendar-day hover:bg-orange-500 hover:rounded-lg cursor-pointer ${clickedDayKey === dayKey ? 'today bg-orange-500 rounded-lg text-white' : ''}`;
                    // const classNames = `calendar-day hover:bg-orange-500 hover:rounded-lg cursor-pointer  ${
                    //     clickedDayKey === dayKey
                    //         ? 'today bg-orange-500 rounded-lg text-white'
                    //         : ''
                    // }`;

                    // week.push(
                    //     <div
                    //         key={dayKey}
                    //         className={classNames}
                    //         onClick={() => handleClick(dayKey)}
                    //     >
                    //         {dayOfMonth}
                    //     </div>
                    // );
                    // dayOfMonth++;
                    const dayKey = `${currentMonth.getFullYear()}-${currentMonth.getMonth() + 1}-${dayOfMonth}`;

                    const unclickedStyles = {
                        width: '150%',
                        height: '150%',
                        borderRadius: '30%',
                        transform: 'translateY(-5%)', // Move the div slightly upward
                        display: 'flex', // Ensure the text is centered inside the div
                        alignItems: 'center', // Vertically center the text
                        justifyContent: 'center', // Horizontally center the text
                        // transition: 'transform 0.3s ease',
                    };
        
                    const clickedStyles = {
                        ...unclickedStyles, // Inherit from unclicked styles
                        backgroundColor: '#F97316', // Set background color for clicked state
                        color: 'white', // Text color for clicked state
                        borderRadius: '0.5rem', // Rounded corners for clicked state
                    };
        
                    week.push(
                        <div
                            key={dayKey}
                            onClick={() => handleClick(dayKey)}
                        >
                            {/* This div controls the background hover and clicked state size */}
                            <div
                                className="calendar-day hover:bg-orange-500 hover:rounded-lg cursor-pointer"
                                style={clickedDayKey === dayKey ? clickedStyles : unclickedStyles}
                            >
                                {/* This div contains the number itself, which won't be affected by the hover */}
                                <span style={{ fontSize: '12px' }}>
                                    {dayOfMonth}
                                </span>
                            </div>
                        </div>
                    );
                    dayOfMonth++;
                    
                }
            }
            weeks.push(week);
        }

        // Meratakan struktur minggu menjadi satu dimensi
        weeks.forEach(week => {
            days.push(...week);
        });

        return days;
    };

    const renderWeeks = () => {
        const weeks = [];

        let dayOfMonth = 1;
        const numberOfWeeks = Math.ceil((daysInMonth + startingDay) / 7);

        // Menambahkan label hari-hari di bagian atas kalender
        weeks.push(
            <div key="week-labels" className={`grid grid-cols-8 gap-4 text-${contentTextSize} font-lato text-center`}>
                <div className="week-label text-orange-400">Week</div>
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                    <div key={day}>{day}</div>
                ))}
            </div>
        );

        for (let i = 0; i < numberOfWeeks; i++) {
            const weekNumber = getWeekNumber(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), dayOfMonth));
            weeks.push(
                <div key={`week-${i}`} className={`grid grid-cols-8 gap-4 text-${contentTextSize} font-lato text-center m-2`}>
                    <div className="week-label text-orange-400">{weekNumber}</div>
                    {renderDays().splice(i * 7, 7)}
                </div>
            );
            dayOfMonth += 7;
        }

        return weeks;
    };

    // Fungsi untuk menghitung minggu dalam tahun
    const getWeekNumber = (date) => {
        const onejan = new Date(date.getFullYear(), 0, 1);
        const weekNum = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
        return weekNum;
    };

    // Fungsi untuk memeriksa apakah dua tanggal sama
    const isSameDay = (date1, date2) => {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    };

    return (
        <div className="flex flex-col bg-orange-200 p-4 rounded-md shadow-md">
            <div className={`flex flex-row items-center justify-between text-${selectTextSize} font-lato font-medium gap-2`}>
                <ChevronSVG direction='left' className='w-4 h-4' onClick={handlePrevMonth} />
                {showPicker ? (
                    <div className="flex gap-2">
                        <select
                            className="px-2 py-1 border border-black rounded-md bg-orange-100 text-black shadow-sm focus:outline-none focus:border-orange-500"
                            value={currentMonth.getMonth()}
                            onChange={handleChangeMonth}
                        >
                            {Array.from({ length: 12 }, (_, i) => (
                                <option key={i} value={i}>
                                    {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                </option>
                            ))}
                        </select>
                        <select
                            className="px-2 py-1 border border-black rounded-md bg-orange-100 text-black shadow-sm focus:outline-none focus:border-orange-500"
                            value={currentMonth.getFullYear()}
                            onChange={handleChangeYear}
                        >
                            {Array.from({ length: 100 }, (_, i) => {
                                const year = new Date().getFullYear() - 50 + i; // Adjust based on your needs
                                return (
                                    <option key={i} value={year}>
                                        {year}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                ) : (
                    <div className='flex gap-2' onClick={handleTogglePicker}>
                        <div>{currentMonth.toLocaleString('default', { month: 'long' })}</div>
                        <div>{currentMonth.getFullYear()}</div>
                    </div>
                )}
                <ChevronSVG direction='right' className='w-4 h-4' onClick={handleNextMonth} />
            </div>
            <hr className="border-orange-400 my-4" />
            {renderWeeks()}
        </div>
    );
};

CustomCalendar.propTypes = {
    currentDate: PropTypes.instanceOf(Date),
    onClick: PropTypes.func,
    selectTextSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    contentTextSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

CustomCalendar.defaultProps = {
    currentDate: new Date(),
    onClick: (dayKey) => console.log("Clicked on day:", dayKey),
    selectTextSize: 'sm',
    contentTextSize: 'xs',
};


export default CustomCalendar;
