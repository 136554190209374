import React, { useRef, useEffect, useState, forwardRef } from "react";
import CalendarMap from "./CalendarMap";
import EmployeeMap from "./EmployeeMap/index";
import { addDays, isBefore, isEqual } from "date-fns";
import PropTypes from "prop-types";

const TestingShiftListBox = forwardRef(
    (
        {
            selectView,
            dataDate,
            shiftDataTemplate,
            dataAssignShift,
            handleClickFormShift,
            handleShowAssign,
            dataEmployees,
            setLocation,
            locationData,
            handleEditAssignShift,
            onChangeLocation,
            // DARI REDUX
            locationState,
            handleConflickAssign,
            roleUser,
            refCalendarMap,
            divHeightEmployee,
            isShiftChoiceRole,
        },
        ref
    ) => {
        const ref1 = useRef();
        const ref2 = useRef([]);
        const [widthScroll, setWidthScroll] = useState();
        const offsetValue = 500

        const handleScroll = (e) => {
            const scrollLeft = e.target.scrollLeft;

            ref1.current.scrollLeft = scrollLeft;

            // GET THE REF IS MUST BE ARRAY IN ARRAY
            ref2.current.forEach((refArray) => {
                refArray.forEach((ref) => {
                    if (ref && ref.scrollLeft !== undefined) {
                        ref.scrollLeft = scrollLeft;
                    }
                });
            });
        };

        // GET MAXWIDTH DIV EVERY MAPPING SCROLL Data
        useEffect(() => {
            const updateScrollWidth = () => {
                if (ref1.current) {
                    const scrollWidth = ref1.current.scrollWidth + offsetValue;
                    setWidthScroll(scrollWidth);
                }
            };

            // Update width on window resize
            window.addEventListener("resize", updateScrollWidth);

            // Initial calculation
            updateScrollWidth();

            // Clean up the event listener on component unmount
            return () => {
                window.removeEventListener("resize", updateScrollWidth);
            };
        }, [
            dataDate,
            shiftDataTemplate,
            dataAssignShift,
            locationState,
            widthScroll,
            selectView,
        ]);

        function generateDateRange(startDate, endDate) {
            let dates = [];
            let currentDate = startDate;

            while (isBefore(currentDate, endDate) || isEqual(currentDate, endDate)) {
                dates.push(currentDate);
                currentDate = addDays(currentDate, 1);
            }

            return dates;
        }

        // GENERATE DATE FROM - TO
        const mapEveryDate =
            shiftDataTemplate.length > 0 &&
            shiftDataTemplate
                .filter((item) =>
                    locationState ? item.locationId === locationState : item
                )
                .map((item, idx) => {
                    const newFrom = new Date(item.repeatDate.from);
                    const newTo = new Date(item.repeatDate.to);
                    const generateDate = generateDateRange(newFrom, newTo);

                    return {
                        ...item,
                        repeatDate: generateDate,
                    };
                });

        // MAP CHECK IF "daily", "weekly", "montyly"

        const filteredData =
            dataDate.length > 0 &&
            dataDate.map((date, indexDate) => {
                const shiftData =
                    mapEveryDate.length > 0 &&
                    mapEveryDate.map((shift, idxShift) => {
                        const repeatValue = shift?.repeatBy && shift.repeatBy.repeatValue;
                        const repeatDate = shift?.repeatDate;

                        if (shift.repeatBy.repeatType === "Weekly") {
                            const dayMapping = {
                                sunday: 0,
                                monday: 1,
                                tuesday: 2,
                                wednesday: 3,
                                thursday: 4,
                                friday: 5,
                                saturday: 6,
                            };

                            const filterByday = repeatDate.filter((repDate) => {
                                const dayOfWeek = repDate.getDay();

                                return (
                                    repeatValue &&
                                    repeatValue.includes(
                                        Object.keys(dayMapping).find(
                                            (key) => dayMapping[key] === dayOfWeek
                                        )
                                    )
                                );
                            });

                            // const boxStatus = filterByday.some(
                            //   (item) => item.getDate() === date.getDate()
                            // );
                            const matchingDate = filterByday.find(
                                (item) => item.getTime() === date.getTime()
                            );

                            const boxStatus = Boolean(matchingDate);

                            return {
                                ...shift,
                                isShowBox: boxStatus,
                                date: matchingDate,
                            };
                        } else {
                            // Calculate the index of the date in the range of repeat dates
                            const indexInRange = repeatDate.findIndex(
                                (rep) => rep.getTime() === date.getTime()
                            );

                            // Determine if the date should be shown
                            const isShowBox =
                                indexInRange !== -1 &&
                                (indexInRange + 1) % (repeatValue + 1) !== 0;

                            return {
                                ...shift,
                                isShowBox: isShowBox,
                                date: date,
                            };
                        }
                    });

                return {
                    date,
                    shiftData,
                };
            });

        // shiftId, organizationId, userId, date, jobPosition => assign model like this
        //
        // FOR EMPLOYEE MAP
        const employeeHover =
            filteredData.length > 0 &&
            filteredData.map((item) => {
                // console.log("SHIFT all : ", item);

                const dateItem = new Date(item.date);

                const atLeastOneTrue =
                    item?.shiftData?.length > 0 &&
                    item?.shiftData?.some((shift) => shift.isShowBox === true);

                // FILTER SHIFT DATA IF TRUE
                const shiftData =
                    item?.shiftData?.length > 0 &&
                    item?.shiftData?.filter((dateFil) => {
                        return dateFil.isShowBox === true;
                    });

                const filteredAssignShift = dataAssignShift
                    ? dataAssignShift.length > 0 &&
                    dataAssignShift.filter((assign) => {
                        const assignDate = new Date(assign.date);
                        return dateItem.getDate() === assignDate.getDate();
                    })
                    : [];

                return {
                    date: item.date,
                    shiftDetails: shiftData,
                    toAssignShift: shiftData,
                    atLeastOneTrue,
                    dataAssignShift: filteredAssignShift,
                };
            });

        // FOR FORM SHIFT
        const hoverAssignShift =
            employeeHover.length > 0 &&
            employeeHover.map((item) => {
                const dateItem = new Date(item.date);

                // FILTER SHIFT
                const allShift =
                    item.toAssignShift &&
                    item.toAssignShift.length > 0 &&
                    item.toAssignShift.filter((detail) => {
                        const detailDate = new Date(detail.date);

                        if (item.dataAssignShift && item.dataAssignShift.length > 0) {
                            // Filter assignShift berdasarkan shiftId dan userId yang cocok dengan uId di shiftDetails
                            const matchedAssignShifts = item.dataAssignShift.filter(
                                (assign) => {
                                    return (
                                        assign.shiftId === detail.uId &&
                                        dateItem.getDate() === detailDate.getDate() &&
                                        assign.jobPosition ===
                                        detail.employees.find(
                                            (emp) => emp.jobPosition === assign.jobPosition
                                        )?.jobPosition
                                    );
                                }
                            );

                            const isShiftNotFull =
                                detail.employees.length > 0 &&
                                detail.employees.some((emp) => {
                                    const matchedShiftsForJobPosition =
                                        matchedAssignShifts.filter(
                                            (assign) => assign.jobPosition === emp.jobPosition
                                        );

                                    // Return shift jika shift belum penuh atau employeesNeeded == 0
                                    return (
                                        matchedShiftsForJobPosition.length < emp.employeesNeeded ||
                                        emp.employeesNeeded === 0
                                    );
                                });

                            // Hanya return shift jika shift belum penuh atau employeesNeeded == 0
                            return isShiftNotFull;
                        }

                        // Jika tidak ada dataAssignShift, return semua shifts
                        return true;
                    });

                return {
                    ...item,
                    toAssignShift: allShift,
                };
            });

        // FOR COUNT TOTAL ASSIGN SHIFT
        const totalAssignShift =
            filteredData.length > 0 &&
            filteredData.map((item) => {
                const filterBaseOnAssignshift =
                    item.shiftData.length > 0 &&
                    item.shiftData.map((shift) => {
                        const shiftDate = new Date(shift.date);
                        const shiftNameShiftData = shift.shiftName;

                        const mapAssignShift =
                            dataAssignShift &&
                            dataAssignShift.length > 0 &&
                            dataAssignShift.filter((assign) => {
                                const dateAssignShift = new Date(assign.date);
                                const assignName =
                                    assign?.shiftDetails && assign.shiftDetails?.shiftName;

                                return (
                                    shiftDate.getDate() === dateAssignShift.getDate() &&
                                    shift.isShowBox &&
                                    shiftNameShiftData === assignName
                                );
                            });

                        return {
                            ...shift,
                            countLength: mapAssignShift && mapAssignShift?.length,
                        };
                    });

                return {
                    ...item,
                    shiftData: filterBaseOnAssignshift,
                };
            });

            const handleWidth = () => {
                const widthScreen = window.innerWidth;
    
                // 1600:w-[73%] 1700:w-[76%] 1800:w-[78%]
    
                if (widthScreen <= 1600) {
                    return "w-[73%]";
                } else if (widthScreen <= 1800) {
                    return "w-[76%]";
                } else if (widthScreen <= 2000) {
                    return "w-[78%]";
                } else if (widthScreen <= 2400) {
                    return "w-[81%]";
                }
            };

        return (
            <div className="mt-5 border-[1px] border-gray-400 w-full flex-shrink-0">
                <div className=" rounded-md flex flex-col select-none ">
                    {/* CALENDAR */}
                    <div ref={refCalendarMap}>
                        <CalendarMap
                            dataDate={dataDate}
                            ref={ref1}
                            handleRef={handleScroll}
                            widthScroll={widthScroll}
                            // DATA SHIFT TEMPLATE
                            filteredData={totalAssignShift}
                            handleClickFormShift={handleClickFormShift}
                            setLocation={setLocation}
                            locationData={locationData}
                            onChangeLocation={onChangeLocation}
                            locationState={locationState}
                            dataAssignShift={dataAssignShift}
                        />
                    </div>

                    {/* EMPLOYEE */}
                    {/* 1600:h-[330px] 1700:h-[380px] 1800:h-[480px] */}

                    <div
                        style={{
                            height: `${divHeightEmployee}px`,
                        }}
                        className={`overflow-y-auto `}
                    >
                        <EmployeeMap
                            dataDate={dataDate}
                            handleRef={handleScroll}
                            ref2={ref2}
                            widthScroll={widthScroll}
                            // DATA SHIFT TEMPLATE
                            filteredData={hoverAssignShift}
                            dataAssignShift={dataAssignShift}
                            handleShowAssign={handleShowAssign}
                            dataEmployees={dataEmployees}
                            handleEditAssignShift={handleEditAssignShift}
                            locationState={locationState}
                            handleConflickAssign={handleConflickAssign}
                            roleUser={roleUser}
                            selectView={selectView}
                        />
                    </div>
                    <div
                        style={{
                            scrollBehavior: "smooth",
                        }}
                        className={`fixed  bottom-[10px] ${handleWidth()}  h-[25px] overflow-x-scroll  ${dataDate.length === 0 && "hidden"
                            }`}
                        onScroll={handleScroll}
                    >
                        <div
                            style={{
                                minWidth: widthScroll,
                            }}
                            className="border-[1px] border-gray-300"
                        ></div>
                    </div> 

                </div>
            </div>
        );
    }
);

export default TestingShiftListBox;

TestingShiftListBox.propTypes = {
    dataDate: PropTypes.array,
    shiftDataTemplate: PropTypes.array,
    dataAssign: PropTypes.array,
    indexAssign: PropTypes.number,
    handleClickFormShift: PropTypes.func,
    handleShowAssign: PropTypes.func,
    setWidthScroll: PropTypes.func,
    widthScroll: PropTypes.number,
    dataEmployees: PropTypes.array,
    locationData: PropTypes.array,
    roleUser: PropTypes.any,
    divHeightEmployee: PropTypes.any,
    selectView: PropTypes.string
};

TestingShiftListBox.defaultProps = {
    dataDate: [],
    shiftDataTemplate: [],
    dataAssign: [],
    indexAssign: 0,
    handleClickFormShift: () => { },
    handleShowAssign: () => { },
    setWidthScroll: () => { },
    widthScroll: 0,
    dataEmployees: [],
    locationData: [],
    roleUser: "",
    divHeightEmployee: 0,
    selectView: ""
};
