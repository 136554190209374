import React, { useEffect, useState } from 'react';
import AppRouter from "routes";
import { useSelector, useDispatch } from 'react-redux';
import { AlertMessage } from '@bluesilodev/timhutcomponents';

import { setLoginReducer } from "store/reducer/user";
import { onAlert } from 'utils/alert';

import RefProvider from 'utils/context';

const App = ({ props }) => {
  const dispatch = useDispatch();
  const { currentUser, currentRole, token } = useSelector((state) => state.userData);

  const [alert, setAlert] = useState(null);

  useEffect(() => {
    // first time set reducer if got localstorage
    const localStorageCurrentUser = JSON.parse(localStorage.getItem("currentUser"));
    const localStorageAccessToken = localStorage.getItem("accessToken");
    const localStorageCurrentRole = localStorage.getItem("currentRole")
    console.log(localStorageCurrentUser);

    if (localStorageCurrentUser && localStorageAccessToken && localStorageCurrentRole) {
      dispatch(setLoginReducer({
        currentUser: localStorageCurrentUser,
        token: localStorageAccessToken,
        currentRole: localStorageCurrentRole
      }));
    } else {
      if (window.__POWERED_BY_QIANKUN__ && !(["/dashboard/login", "/dashboard/register"].includes(window.location.pathname))) {
        window.location.href = "/"
      }
    }

    // alert
    const subscription = onAlert().subscribe(alert => {
      setAlert(alert);
      setTimeout(() => setAlert(null), alert.dismissSecond || 3000); // Hide the alert after dismissSecond
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (typeof props?.setGlobalState === "function") {
      props.setGlobalState({
        currentUser: currentUser,
        accessToken: token,
        currentRole: currentRole
      });
    }
    console.log('qiankun global state update')
  }, [props, currentUser, token, currentRole]);

  return (
    <React.Fragment>
      <RefProvider>
        <AppRouter />
        {alert && <AlertMessage type={alert.type} title={alert.title} message={alert.message} dismissSecond={alert.dismissSecond || 3000} />}
      </RefProvider>
    </React.Fragment>
  );
}

export default App;
